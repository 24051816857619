import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import './components.css';
import { Loader } from "../components/loader";
import { Link, useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import logo from  "../images/logo.png"
import dispute from "../images/parties.png"
import Swal from "sweetalert2";
export const Aside: FC = () => {
    const location: any = useLocation()
    const [createDispute, setcreateDispute] = useState(false);
    const { publicKey } = useWallet();
    const [disputePorjectId, setdisputePorjectId] = useState("");
    const [disputeDiscription, setdisputeDiscription] = useState("");
    const [totalContract, settotalContract] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activemenu, setactivemenu] = useState('dashboard');

    function active_menu(val){
        setactivemenu(val)
    }


    let handleSubmit = async (e) => {
        setIsLoading(true)
        console.log("dd")
        e.preventDefault();
        let iserror = false;
        if (disputePorjectId.length === 0) {
            iserror = true;
		}
        if (disputeDiscription.length === 0) {
			iserror = true;
		}
        if(!iserror){
            try {
                // let res = await fetch(process.env.REACT_APP_API_URL+"createDispute", {
                    let res = await fetch(process.env.REACT_APP_API_URL+"createDispute", {
                    method: "POST",
                    mode: 'cors',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify({
                        "disputeDescription": disputeDiscription,
                        "mileStoneId": "string",
                        "projectId": disputePorjectId,
                        "walletAdress": publicKey.toString()
                    }),
                });
                let resJson = await res.text();
                console.log(resJson)
                setIsLoading(false)
                setcreateDispute(false)
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Your Dispute Has Submitted Successfully. ',
                        text: 'Our team will get back to you shortly',
                        confirmButtonText:
                            'Okey'
                    })
                } else {
                    console.log("failure")
                }
            } catch (err) {
                console.log(err);
            }
        }else{
            setIsLoading(false)
			Swal.fire({
				title: 'Some Details Are Empty',
				text: 'Please check and submit again.',
				confirmButtonText:
					'Okey'
			})
        }
  
    };

    useEffect(() => {
		if (location.state && location.state.from == 'my-project') {
			setactivemenu('My Projects');
		} else if (location.state && location.state.from == 'dashboard') {
			setactivemenu('Dashboard');
		} else if (location.state && location.state.from == 'transactions') {
			setactivemenu('Transactions');
		} else if (location.state && location.state.from == 'chat') {
			setactivemenu('Chat');
		}
	}, [location.state]);

    
    const handleClose = () => setcreateDispute(false);

    function open_create_dispute_modal() {
        setcreateDispute(true)
    }

    // const getAllContract = async () => {
    //     try {
    //         console.log("aside call started")
    //         const response = await fetch(process.env.REACT_APP_API_URL+'getAllContracts/' + publicKey.toString(), { mode: 'cors' });
    //         const data = await response.json();
    //         console.log(data['buyerContracts'] + data['sellerContracts'])
    //         settotalContract([...data['buyerContracts'], ...data['sellerContracts']])
    //         console.log("aside call done")
    //     }
    //     catch (e) {
    //         console.log(e)
    //     }
    // }


    useEffect(() => {
        if (publicKey) {
            localStorage.setItem('p_key', publicKey.toString())
          //  getAllContract();
        }
    }, [publicKey]);

    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    return (<>
     {isLoading && <Loader/> }
        {/* <Modal show={createDispute} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Create Dispute</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} id="kt_modal_profile_update_form" className="form" action="#">

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">


                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">Choose The Project</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Select the contract type."></i>
                        </label>


                        <select
                            className="form-select form-select-solid"

                            name="created_for"
                            onChange={(e) => setdisputePorjectId(e.target.value)} >
                                <option>Choose the Project</option>
                            {totalContract.map(contract =>
                                <option key={contract.projectId} value={contract.projectId}>{contract.projectTitle}</option>
                            )}

                        </select>

                    </div>

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                        <label className="required fs-5 fw-bold mb-2">Dispute Description</label>

                        <textarea
                            className="form-control form-control-solid"
                            rows={3} name="description"
                            placeholder="Describe your dispute"
                            onChange={(e) => setdisputeDiscription(e.target.value)}></textarea>
                    </div>

                    <div className="text-center">
                        <button type="reset" className="btn btn-light me-3" onClick={handleClose}>Cancel</button>
                        <button onClick={handleSubmit} type="submit" className="btn btn-primary">
                            <span className="indicator-label">Submit Dispute</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>

                </form>
            </Modal.Body>


        </Modal> */}
        <div id="kt_aside" className="aside bg-primary" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_toggle">

            <div className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8" id="kt_aside_logo">
                <a href="/">
                    <img alt="Logo" src={logo} className="h-55px" />
                </a>
            </div>

            <div className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0" id="kt_aside_nav">

                <div id="kt_aside_menu" className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6" data-kt-menu="true">
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" className={activemenu == 'Dashboard'?'menu-item here show py-3' :'menu-item here py-3'}>
                        <Link className="aside-menu-title" to={{ pathname: '/' }} state={{ from: "dashboard" }}>
                            <span className="menu-link menu-center" title="" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right" data-bs-original-title="Dashboards">
                                <span className="menu-icon me-0">
                                    <i className="bi bi-bar-chart-line fs-2"></i>
                                </span>
                            </span>
                            <span className="aside-menu-title">Dashboard</span>
                        </Link>
                    </div>
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" className={activemenu == 'My Projects'?'menu-item here show py-3' :'menu-item here py-3'}>
                        <Link className="aside-menu-title" to={{ pathname: '/my-project' }} state={{ from: "my-project" }}>
                            <span className="menu-link menu-center" title="" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right" data-bs-original-title="Projects">
                                <span className="menu-icon me-0">
                                    <i className="bi bi-file-text fs-2"></i>
                                </span>
                            </span>
                            <span className="aside-menu-title">Projects</span>
                        </Link>
                    </div>
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" className={activemenu == 'Transactions'?'menu-item here show py-3' :'menu-item here py-3'}>
                        <Link className="aside-menu-title" to={{ pathname: '/transactions' }} state={{ from: "transactions" }} >
                            <span className="menu-link menu-center" title="" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right" data-bs-original-title="Transactions">
                                <span className="menu-icon me-0">
                                    <i className="bi bi-window-stack fs-2"></i>
                                </span>
                            </span>
                            <span className="aside-menu-title">Transactions</span>
                        </Link>
                    </div>
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" className={activemenu == 'Chat'?'menu-item here show py-3' :'menu-item here py-3'}>
                        <Link className="aside-menu-title" to={{ pathname: '/chat' }} state={{ from: "chat" }} >
                            <span className="menu-link menu-center" title="" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right" data-bs-original-title="Chat">
                                <span className="menu-icon me-0">
                                    <i className="bi bi-chat fs-2"></i>
                                </span>
                            </span>
                            <span className="aside-menu-title" >Chat</span ></Link>
                    </div>
                </div>

            </div>
            <div className="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer">

                <div className="">
                <Link className="aside-menu-title" to={{ pathname: '/support-ticket' }} state={{ from: "support-ticket" }} >
                <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#FFFFFF" fillRule="nonzero" opacity="0.3"/>
        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#FFFFFF" fillRule="nonzero"/>
    </g>
</svg> </span>   
                        </Link>
                </div>
                <span className="aside-menu-title mb-7" style={{fontWeight:"600"}}>Support Ticket</span>
            </div>

            <div className="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer">

                <div className="">
                <Link className="aside-menu-title" to={{ pathname: '/resolution-management' }} state={{ from: "resolution-management" }} >
                <img width={40} src={dispute} />    
                        </Link>
                    {/* <button type="button" onClick={open_create_dispute_modal} className="btn btm-sm btn-icon btn-color-white">

                        <img width={40} src={dispute} />

                    </button> */}
                    


                </div>
                <span className="aside-menu-title mb-7" style={{fontWeight:"600"}}>Dispute</span>
            </div>

        </div>


    </>);
};