export class UserModel {
  title: string;
  walletid: string;
  name: string;
  last_message: string;
  last_message_time: string;
  is_active: boolean;
  constructor(
    title: string = "",
    walletid: string = "",
    name: string = "",
    last_message: string = "",
    last_message_time: string = "",
    is_active: boolean = false
  ) {
    this.title = title;
    this.walletid = walletid;
    this.name = name;
    this.last_message = last_message;
    this.last_message_time = last_message_time;
    this.is_active = is_active;
  }
}
