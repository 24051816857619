export class MessageRequestModel {
  title: string;
  walletid: string;
  name: string;
  request_time: string;
  is_active: boolean;
  status: string;
  constructor(
    title: string = "",
    walletid: string = "",
    name: string = "",
    request_time: string = "",
    is_active: boolean = false,
    status: string = ""
  ) {
    this.title = title;
    this.walletid = walletid;
    this.name = name;
    this.request_time = request_time;
    this.is_active = is_active;
    this.status = status;
  }
}
