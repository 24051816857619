import { chatMessagesModel } from "./chatMessagesModel";

export class chatdetailsModel {
  title: string;
  walletid: string;
  name: string;
  is_active: boolean;
  is_messages_loaded: boolean = false;
  chat_messages: chatMessagesModel[];
  constructor(
    title: string = "",
    walletid: string = "",
    name: string = "",
    is_active: boolean = false,
    is_messages_loaded: boolean = false,
    chat_messages: chatMessagesModel[] = []
  ) {
    this.title = title;
    this.walletid = walletid;
    this.name = name;
    this.is_active = is_active;
    this.chat_messages = chat_messages;
    this.is_messages_loaded = is_messages_loaded;
  }
}
