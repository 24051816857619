import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";

// Use require instead of import, and order matters
require("@solana/wallet-adapter-react-ui/styles.css");
require("./index.css");



ReactDOM.render(
  <StrictMode>
     <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
