import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./components.css";

export const Loader: FC = () => {


    return (<>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    </>);
};