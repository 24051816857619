import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import './login.css'
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
    WalletDisconnectButton,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import  logo  from "../images/logo.png";
import  login_icon  from "../images/login-icon.png";
import  twitter  from "../images/twitter.png";
import  discord  from "../images/discord.png";
// import styled from "styled-components";

// const ConnectButton = styled(WalletMultiButton)`&&{
//     display: inline-block;
//     position: relative;
//     background: rgb(0 0 0 / 12%);
//     border: solid 2px #fff;
//     border-radius: 21px;
//     font-size: 12px;
//     font-family: 'My SoraExtra Bold font';
//     font-weight: bold;
//     text-transform: uppercase;
//     color: white;
//     //padding: 20px 40px;
//     // border: none;
//     // border-radius: 50px;
//     //margin: 10px;
//     cursor: pointer;
//     transition: transform 0.1s, box-shadow 0.1s;
//     &:hover{
//       text-shadow: #f7a828 1px 0 10px;
//       background-color: rgb(0 0 0 / 12%);
//       color: white;
//   }

//   }
//   `;

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

export const Login: FC = () => {
    const { connection } = useConnection();
    const { wallet, publicKey, sendTransaction } = useWallet();
    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    return (<>
        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style={{ backgroundColor: "#1e4395" }}>

            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y" style={{ backgroundColor: "#1e4395" }}>

                <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">

                    <a href="#" className="py-9 mb-5">
                        <img alt="Logo" src={logo} className="h-60px" />
                    </a>


                    <h1 className="fw-bolder fs-2qx pb-5 pb-md-10" style={{ color: "#fff" }}>Welcome to Vault-X</h1>


                    <p className="" style={{ color: "#fff", fontSize: "17px" }}>Vault-X keeps your funds secure while dealing with anonymous people. Our Protection protocol for Web 3.0 safeguards the interests of both the buyer and the seller through a decentralized and autonomous vault contract.</p>

                    <div className="gap-5 mt-5 mb-2 m-auto">
                        <a href="https://twitter.com/TheVaultDAO" target={"blank"} className="">
                            <img src={twitter} className="w-50px" alt="" />
                        </a>
                        <a href="https://discord.gg/CaSVfkCbvc" target={"blank"}>
                            <img src={discord} className="w-50px" alt="" />
                        </a>

                    </div>
                </div>






            </div>

        </div>


        <div className="d-flex flex-column flex-lg-row-fluid py-10">

            <div className="d-flex flex-center flex-column flex-column-fluid">

                <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                    <div className="login-gif-animation d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{}}>
                        <img src={login_icon} />
                    </div>
                    <form className="form w-100">

                        <div className="text-center mb-10">

                            <WalletMultiButton />
                            {/* <ConnectButton>Connect Wallet</ConnectButton> */}

                        </div>



                    </form>

                </div>

            </div>


        </div>
    </>);
};