import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import "./notification.css";
import notification_img from '../images/notification.png'
import moment from "moment";
import { useNavigate, Link } from 'react-router-dom';
export const Notifications: FC = () => {
    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    const [isLoading, setIsLoading] = useState(false);
    const { publicKey } = useWallet();
    const [notifications, setnotifications] = useState([])
    const [unreadNotifications, setunreadNotifications] = useState([])
    const navigate = useNavigate();

    const get_notifications = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'getAllMessages/' + publicKey.toString(), { mode: 'cors' });
            const data = await response.json();
            console.log(data)
            setnotifications(data.reverse())
            setIsLoading(false)
            let t = []
            data.forEach(element => {
                if (element.readStatus == false) {
                    t.push({ "messageId": element.messageId, "readStatus": true })
                }
            });
            if (t.length) {
                setunreadNotifications(t)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const update_notifications = async () => {
        if (unreadNotifications.length > 0) {
            try {
                let res = await fetch(process.env.REACT_APP_API_URL + "updateMessageStatus", {
                    method: "POST",
                    mode: 'cors',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify({
                        "messageStatusRequests": unreadNotifications,
                    }),
                });
                let resJson = await res.text();
                if (res.status === 200) {
                    console.log("success")
                } else {
                    console.log("failure")
                }
            } catch (err) {
                console.log(err);
            }
        }

    }

    let call_project_details = param => e => {
        console.log(param)
        localStorage.setItem('project_id', param)
        navigate('/project-milestone/' + param);
    }


    const gettime = (timestamp) => {


        let date = timestamp[0] + "-" + timestamp[1] + "-" + timestamp[2] + " " + timestamp[3] + ":" + timestamp[4] + ":" + timestamp[5];
        var stillUtc = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').toDate();
        var local = moment(stillUtc, 'YYYY-MM-DD HH:mm:ss').local().format('MMMM Do YYYY, h:mm a')

        return local;
    }

    useEffect(() => {
        setIsLoading(true)
        if (publicKey) {
            get_notifications();
            //   setInterval(init, 1000);
        }
    }, [publicKey]);

    useEffect(() => {
        if (unreadNotifications.length > 0) {
            update_notifications();
            //   setInterval(init, 1000);
        }
    }, [unreadNotifications]);

    return (<>
        {isLoading && <Loader />}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">
                <div className="row g-5 g-xl-8">

                    <div className="col-xl-10">

                        <div className="d-flex flex-wrap flex-stack">
                            <h3 className="fw-bolder my-2">Notifications
                            </h3>
                        </div>
                        <div className="tab-content pt-8">
                            <div className="card card-flush">
                                <div className="card-body pt-3">

                                    <table id="kt_profile_overview_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">



                                        {(notifications.length > 0) ? (
                                            <tbody className="fs-6">
                                                {notifications.map((notification, index) => (
                                                    <tr>
                                                        <td>
                                                            <img width={40} src={notification_img} />
                                                        </td>
                                                        <td className="notification-msg">
                                                            {notification.message}	<a href="#" onClick={call_project_details(notification.projectId)}>View detail </a>
                                                            <div className="notification-date">{gettime(notification.createdTimeStamp)}</div>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        ) :
                                            <tbody className="fs-6">
                                                <tr>
                                                    <td colSpan={2} className="text-center text-primary">
                                                        No recent notification
                                                    </td>
                                                </tr>
                                            </tbody>}



                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </>);
};