import React, { FC, useCallback, useEffect, useReducer, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { Loader } from "../components/loader";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import './create-project-modal.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
	createEscrowInitTransaction,
	createEscrowValidateTransaction,
	createEscrowApproveTransaction,
	createInitiatorWithdrawTransaction,
	createSPLEscrowInitTransaction,
	createSPLEscrowValidateTransaction,
	createSPLEscrowApproveTransaction,
	createSPLInitiatorWithdrawTransaction,
	printEscrowData
} from "../util/escrow";

var $: any;




export const CreateProejctModal = ({ createProject, socket, setcreateProject }) => {
	let navigate = useNavigate()
	const { connection } = useConnection();
	const { wallet, publicKey, sendTransaction } = useWallet();
	const [totalcontractCost, settotalcontractCost] = useState(0);
	const [contractName, setcontractName] = useState("");
	const [createdFor, setcreatedFor] = useState("buyer");
	const [walletAddress, setwalletAddress] = useState("");
	const [totalCost, settotalCost] = useState("");
	const [projectDescription, setprojectDescription] = useState("");
	const [dueDate, setdueDate] = useState(new Date());
	const [inputFields, setInputFields] = useState([
		{ mileStoneNo: '', mileStoneTitle: '', mileStoneDescription: '', amount: '', dueDate: '', dueDateString: '', status: null }
	])
	const [milestonebased, setmilestonebased] = useState('nomilestone');

	const [validator, setvalidator] = useState("");
	const [escrowVaultWord, setescrowVaultWord] = useState("");
	const [transactionSOL, settransactionSOL] = useState(0);
	const [project_id, setproject_id] = useState('');
	const [milestone_id, setmilestone_id] = useState('');
	const [error, setErrors] = useState(false)
	const [iswalletvaild, setiswalletvaild] = useState(false)
	const [isbalancevaild, setisbalancevaild] = useState(false)
	const [isLoading, setIsLoading] = useState(false);



	const handleFormChange = (index, event, type = '') => {
		let data = [...inputFields];
		if (type == 'date') {
			data[index]['dueDateString'] = event;
			data[index]['dueDate'] = moment(event).format("DD/MM/YYYY");
		}
		// else if (type == 'cost') {
		// 	console.log(event.target.value)
		// 	if (index == 0) {
		// 		set_milestone_cost(event.target.value)
		// 	}
		// 	data[index][event.target.name] = event.target.value;
		// }
		else {
			data[index][event.target.name] = event.target.value;
		}
		setInputFields(data);
	}

	const addFields = () => {
		let newfield = { mileStoneNo: '', mileStoneTitle: '', mileStoneDescription: '', amount: '', dueDate: '', dueDateString: '', status: null }

		setInputFields([...inputFields, newfield])
	}

	const removeFields = (index) => {
		let data = [...inputFields];
		data.splice(index, 1)
		setInputFields(data)
		if (data.length > 0) {
			set_milestone_cost(data[0].amount)
		}
	}



	let validate = async () => {
		// we are going to store errors for all fields
		// in a signle array
		const errors = [];
		localStorage.setItem('error', 'false')

		if (contractName.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}

		if (createdFor.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}
		if (walletAddress.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}
		if (totalCost.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}

		if (projectDescription.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}

		if (projectDescription.length === 0) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}
		if (milestonebased == 'milestonebased') {

			if (inputFields.length === 0) {
				localStorage.setItem('error', 'true')
				setErrors(true)
			} else {
				for (let index = 0; index < inputFields.length; index++) {
					if (inputFields[index].mileStoneTitle == '' ||
						inputFields[index].mileStoneDescription == '' ||
						inputFields[index].amount == '' ||
						inputFields[index].dueDate == '') {
						localStorage.setItem('error', 'true')
						setErrors(true)
					}
				}

			}
		} else {
			if (!dueDate) {
				localStorage.setItem('error', 'true')
				setErrors(true)
			}
		}
		if (!dueDate) {
			localStorage.setItem('error', 'true')
			setErrors(true)
		}

		return error;
	}

	let handleSubmit = async (e) => {

		e.preventDefault();

		let wallet_valid = await vaildate_wallet_address(walletAddress)
		if (wallet_valid) {
			let cost_valid = false;
			if (milestonebased == 'milestonebased') {
				cost_valid = await set_milestone_cost(inputFields[0].amount)
			} else {
				cost_valid = await set_contract_cost(totalCost)
			}

			if (cost_valid) {
				setIsLoading(true)
				await validate()
				let iserror = false;
				if (localStorage.getItem('error') == 'true') {
					iserror = true
				}
				if (localStorage.getItem('iswalletvaild') == 'false') {
					iserror = true
				}
				if (localStorage.getItem('isbalancevaild') == 'false') {
					iserror = true
				}
				if (!iserror) {
					let mileStoneBased = false;
					let seller_address = '';
					let buyer_addresss = '';
					// let milestone_data = inputFields;
					let milestone_data = []
					if (createdFor == 'seller') {
						seller_address = publicKey.toString();
						buyer_addresss = walletAddress;
						setvalidator(walletAddress)

					}
					else {
						seller_address = walletAddress;
						buyer_addresss = publicKey.toString();
						setvalidator(walletAddress)
					}
					if (milestonebased === 'milestonebased') {
						mileStoneBased = true;
						for (let index = 0; index < inputFields.length; index++) {
							let temp =
							{
								mileStoneNo: inputFields[index]['mileStoneNo'],
								mileStoneTitle: inputFields[index]['mileStoneTitle'],
								mileStoneDescription: inputFields[index]['mileStoneDescription'],
								amount: inputFields[index]['amount'],
								dueDate: inputFields[index]['dueDate'],
								status: null
							}
							milestone_data.push(temp)
						}
						// milestone_data = inputFields;
					} else {
						milestone_data = [
							{
								mileStoneNo: '',
								mileStoneTitle: contractName,
								mileStoneDescription: projectDescription,
								amount: totalCost,
								dueDate: moment(dueDate).format("DD/MM/YYYY"),
								status: ''
							}
						]
					}
					let project_data = {
						"createdBy": createdFor,
						"sellerAddress": seller_address,
						"buyerAddress": buyer_addresss,
						"projectTitle": contractName,
						"projectDescription": projectDescription,
						"dueDate": null,
						"amount": totalCost,
						"mileStones": milestone_data,
						"mileStoneBased": mileStoneBased
					}

					try {
						let res = await fetch(process.env.REACT_APP_API_URL + "createContract", {
							method: "POST",
							mode: 'cors',
							headers: new Headers({ 'content-type': 'application/json' }),
							body: JSON.stringify({
								"createdBy": createdFor,
								"sellerAddress": seller_address,
								"buyerAddress": buyer_addresss,
								"projectTitle": contractName,
								"projectDescription": projectDescription,
								"dueDate": null,
								"amount": totalCost,
								"mileStones": milestone_data,
								"mileStoneBased": mileStoneBased
							}),
						});
						let resJson = await res.json();
						if (res.status === 200) {
							setproject_id(resJson.projectId)
							localStorage.setItem('p_id', resJson.projectId)
							localStorage.setItem('m_id', resJson.mileStones[0].mileStoneId)
							setmilestone_id(resJson.mileStones[0].mileStoneId)
							setvalidator(walletAddress)
							settransactionSOL(Number(resJson.mileStones[0].amount))
							localStorage.setItem('validator', walletAddress)
							localStorage.setItem('transactionSOL', resJson.mileStones[0].amount)
							let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + resJson.projectId + "/" + resJson.mileStones[0].mileStoneId, {
								mode: 'cors',
							});
							let access_key = await access_res.text();
							localStorage.setItem('escrowVaultWord', access_key)
							await EscrowInit();


						} else {
							console.log("failure")
						}
					} catch (err) {
						console.log(err);
					}
				} else {
					setIsLoading(false)
					Swal.fire({
						title: 'Empty or invalid form fields',
						text: 'Please review all the fields',
						confirmButtonText:
							'Close'
					})
				}
			} else {
				setIsLoading(false)
			}

		} else {
			setIsLoading(false)
		}


	};



	let notify_user = async (msg, to_address) => {
		let res = await fetch(process.env.REACT_APP_API_URL + "saveMessage", {
			method: "POST",
			mode: 'cors',
			headers: new Headers({ 'content-type': 'application/json' }),
			body: JSON.stringify({
				"fromAddress": publicKey.toString(),
				"message": msg,
				"milestoneId": localStorage.getItem('m_id'),
				"projectId": localStorage.getItem('p_id'),
				"readStatus": false,
				"toAddress": to_address

			}),
		});
		let resJson = await res.json();
		if (res.status === 200) {
			socket?.emit("sendText", {
				message: msg,
				from: publicKey.toString(),
				to: to_address
			});
		} else {
			console.log("failure")
		}



	}

	const sendAndConfirmTransaction = useCallback(

		async (transaction) => {
			try {
				let { blockhash } = await connection.getRecentBlockhash();
				transaction.feePayer = publicKey;
				transaction.recentBlockhash = blockhash;

				let signature = await sendTransaction(transaction, connection);
				await connection.confirmTransaction(signature, "confirmed");
				return signature;
			} catch (err) {
				setIsLoading(false)
				setcreateProject(false)
				console.log("Transaction error: ", err);
				Swal.fire({
					title: "Error in transaction",
					text: err.message,
					confirmButtonText:
						'Close'
				})
			}
		},
		[connection, publicKey, sendTransaction]
	);


	// If Buyer is creating deposit = amount
	// If Seller is creating deposit = 0
	const EscrowInit = useCallback(
		async () => {
			if (!publicKey) return;

			let deposit = 0;
			if (createdFor == 'buyer') {
				deposit = Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL;
			}
			console.log("walletAddress " + localStorage.getItem('validator'))
			console.log('escrowVaultWord ' + localStorage.getItem('escrowVaultWord'))
			console.log('transactionSOL ' + localStorage.getItem('transactionSOL'))
			console.log('transactionSOL ' + Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL)
			console.log('deposit ' + deposit)
			let sig = await sendAndConfirmTransaction(
				await createEscrowInitTransaction(connection, publicKey, new PublicKey(localStorage.getItem('validator')), localStorage.getItem('escrowVaultWord'), Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL, deposit)
			);

			if (sig) {
				let data = {
					"milestoneId": localStorage.getItem('m_id'),
					"projectId": localStorage.getItem('p_id'),
					"signature": sig,
					"walletAddress": publicKey.toString()
				}

				// Update the contract API
				try {

					let res = await fetch(process.env.REACT_APP_API_URL + "initiateContract", {
						method: "POST",
						mode: 'cors',
						headers: new Headers({ 'content-type': 'application/json' }),
						body: JSON.stringify(data),
					});
					let resJson = await res.json();
					setIsLoading(false)
					setcreateProject(false)
					if (res.status === 200) {
						localStorage.setItem('project_id', resJson.projectId);
						localStorage.setItem('p_key', publicKey.toString())
						if (createdFor == 'buyer') {
							notify_user("Buyer created the contract", localStorage.getItem('validator'));
						} else {
							notify_user("Seller created the contract", localStorage.getItem('validator'));
						}

						Swal.fire({
							title: 'Your transaction was successful',
							text: 'Your contract has been created successfully',
							confirmButtonText:
								'Click to view contract'
						}).then((result) => {
							navigate("/project-milestone/" + resJson.projectId, { replace: true });
							// window.location.href = '/my-project';
							// /* Read more about isConfirmed, isDenied below */
							// if (result.isConfirmed) {
							// 	Swal.fire('Saved!', '', 'success')
							// } else if (result.isDenied) {
							// 	Swal.fire('Changes are not saved', '', 'info')
							// }
						})

						console.log("success")

					} else {
						console.log("failure")
					}
				} catch (err) {
					console.log(err);
				}
			}
		},
		[connection, publicKey, walletAddress, project_id, milestone_id, transactionSOL, createdFor, sendAndConfirmTransaction]
	);

	let close_modal = async (e) => {

	}


	const handleClose = () => setcreateProject(false);

	// Check the balance and calculate the total cost of project
	let set_contract_cost = async (cost) => {

		if (cost.length != 0) {
			if (cost > 0) {
				localStorage.setItem('isbalancevaild', 'true')
				const balance = await connection.getBalance(publicKey);
				settotalCost(cost);
				let t = (Number(cost) + 0.000005 + 0.00151728);
				settotalcontractCost(t);
				// Amout+3.5%(AMount)+0.000005+0.00151728
				if (((balance / LAMPORTS_PER_SOL)) < t) {
					setErrors(true)
					setisbalancevaild(false)
					localStorage.setItem('isbalancevaild', 'false')
					let remaining_amt = t - (balance / LAMPORTS_PER_SOL);
					Swal.fire({
						title: "You have insufficient funds in your wallet",
						text: "You need " + remaining_amt.toFixed(5) + " SOL more to create this contract",
						confirmButtonText:
							'Close'
					})
					return false;
				} else {
					setisbalancevaild(true)
					return true
				}
			} else if (cost == 0) {

				Swal.fire({
					title: "Cost of total contract is zero",
					text: "please enter the valid contract cost",
					confirmButtonText:
						'Close'
				})
				return false;
			} else {

				Swal.fire({
					title: "Cost of total contract is negative",
					text: "please enter the valid contract cost",
					confirmButtonText:
						'Close'
				})
				return false;
			}
		} else {

			Swal.fire({
				title: "Cost of total contract is empty",
				text: "please enter the contract cost",
				confirmButtonText:
					'Close'
			})
			return false;
		}
	}

	let set_milestone_cost = async (cost) => {
		if (cost.length != 0) {
			if (cost > 0) {
				localStorage.setItem('isbalancevaild', 'true')
				const balance = await connection.getBalance(publicKey);
				let t = (Number(cost) + 0.000005 + 0.00151728);
				settotalcontractCost(t);
				// Amout+3.5%(AMount)+0.000005+0.00151728
				if (((balance / LAMPORTS_PER_SOL)) < t) {
					setErrors(true)
					setisbalancevaild(false)
					localStorage.setItem('isbalancevaild', 'false')
					let remaining_amt = t - (balance / LAMPORTS_PER_SOL);
					Swal.fire({
						title: "You have insufficient funds in your wallet",
						text: "You need " + remaining_amt.toFixed(5) + " SOL more to create this contract",
						confirmButtonText:
							'Close'
					})
					return false
				} else {
					setisbalancevaild(true)
					let total_milestone_amount = 0;
					for (let index = 0; index < inputFields.length; index++) {
						if (inputFields[index].amount != '' && inputFields[index].amount.length > 0) {
							total_milestone_amount = total_milestone_amount + Number(inputFields[index].amount);
						}
					}
					if (Number(totalCost) >= total_milestone_amount) {
						return true
					} else {
						Swal.fire({
							title: "Cost of total contract is less than the sum of all milestone cost",
							text: "please enter the valid cost",
							confirmButtonText:
								'Close'
						})
						return false
					}
				}
			} else if (cost == 0) {

				Swal.fire({
					title: "Cost of total contract is zero",
					text: "please enter the valid contract cost",
					confirmButtonText:
						'Close'
				})
				return false;
			} else {

				Swal.fire({
					title: "Cost of milestone cost is negative",
					text: "please enter the valid cost",
					confirmButtonText:
						'Close'
				})
				return false;
			}
		} else {

			Swal.fire({
				title: "Cost of milestone cost is empty",
				text: "please enter the cost",
				confirmButtonText:
					'Close'
			})
			return false;
		}
	}

	// Check the balance and calculate the total cost of project
	let vaildate_wallet_address = async (w_address) => {

		if (w_address != '') {
			if (w_address.length >= 32 && w_address.length <= 44) {
				localStorage.setItem('iswalletvaild', 'true')
				setwalletAddress(w_address)
				if (w_address == publicKey.toString()) {
					setErrors(true)
					setiswalletvaild(false)
					localStorage.setItem('iswalletvaild', 'false')
					Swal.fire({
						title: "Oops! You entered your wallet address",
						text: "Please add a valid Seller wallet address",
						confirmButtonText:
							'Close'
					})
					return false
				} else {
					// To check the wallet is vaild
					try {
						setIsLoading(true)
						let res = await fetch(process.env.REACT_APP_API_URL + "validateAddress/" + w_address, {
							method: "GET",
							mode: 'cors',
							headers: new Headers({ 'content-type': 'application/json' })
						});
						if (res.status === 200) {
							setiswalletvaild(true)
							localStorage.setItem('iswalletvaild', 'true')
							return true
						} else if (res.status == 202) {

							localStorage.setItem('iswalletvaild', 'false')
							setIsLoading(false)
							setErrors(true)
							setiswalletvaild(false)
							Swal.fire({
								title: "Invalid Solana wallet address",
								text: "Please double check the entered address",
								confirmButtonText:
									'Close'
							})
							return false
						} else {
							console.log("failure")
						}
					} catch (err) {
						console.log(err);
					}
				}
			} else {
				setiswalletvaild(false)
				Swal.fire({
					title: "Invalid Solana wallet address",
					text: "Please enter the valid address",
					confirmButtonText:
						'Close'
				})
				return false
			}
		} else {
			setiswalletvaild(false)
			Swal.fire({
				title: "Invalid Solana wallet address",
				text: "Please enter the valid address",
				confirmButtonText:
					'Close'
			})
			return false
		}
	}

	let vaildate_cost = async (cost) => {
		if (cost > 0) {
			set_contract_cost(cost)
		} else {

		}
	}

	useEffect(() => {
		setcontractName("");
		setwalletAddress("");
		settotalCost("");
		setprojectDescription("");
		setmilestonebased('nomilestone');
		setInputFields([
			{ mileStoneNo: '', mileStoneTitle: '', mileStoneDescription: '', amount: '', dueDate: '', dueDateString: '', status: null }
		])
	}, [createProject])


	useEffect(() => {
		settotalcontractCost(0)
	}, [totalCost]);


	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: 'auto'
			/* you can also use 'auto' behaviour 
			   in place of 'smooth' */
		});
	};


	return (<>
		{isLoading && <Loader />}
		<Modal show={createProject} onHide={handleClose} dialogClassName="modal-90w" backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Create Contract</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit} id="kt_modal_new_contract_form" className="form" action="#">
					<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
						<div className="col">
							<div className="fv-row mb-8">

								<label className="d-flex align-items-center fs-6 fw-bold mb-2">
									<span className="required">Created By</span>

								</label>


								<select
									className="form-select form-select-solid"

									name="created_for"
									onChange={(e) => setcreatedFor(e.target.value)} >
									{/* <option value="seller">Seller</option> */}
									<option value="buyer">Buyer</option>
								</select>

							</div>

						</div>
						<div className="col">
							<div className="d-flex flex-column mb-8 fv-row">

								<label className="d-flex align-items-center fs-6 fw-bold mb-2">
									<span className="required">Contract Name / Project Name</span>


								</label>

								<input
									type="text"
									onChange={(e) => setcontractName(e.target.value)}
									className="form-control form-control-solid"
									placeholder="Enter Contract Name"
									name="contract_name" />
							</div>

						</div>
					</div>


					<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
						<div className="col">
							<div className="d-flex flex-column mb-8 fv-row">

								<label className="d-flex align-items-center fs-6 fw-bold mb-2">
									{/* <span className="required">Wallet Address of Second Party</span> */}
									<span className="required">Seller Wallet Address</span>


								</label>
								<div className="input-group">
									<input
										type="text"
										className="form-control form-control-solid"
										placeholder="Enter Wallet Address"
										name="wallet_address"
										onChange={(e) => setwalletAddress(e.target.value)}
									/>
									{/* {walletAddress && iswalletvaild &&
										<div className="btn btn-icon btn-light">

											<i className="wallet-vaild-icon bi bi-check"></i>

										</div>
									}
									{walletAddress && !iswalletvaild &&
										<div className="btn btn-icon btn-light">
											<i className="wallet-invaild-icon bi bi-exclamation-triangle"></i>


										</div>
									} */}

								</div>

							</div>

						</div>
						<div className="col">
							<div className="d-flex flex-column mb-8 fv-row">

								<label className="d-flex align-items-center fs-6 fw-bold mb-2">
									<span className="required">Cost Of Total Contract / Project (SOL)</span>


								</label>


								<div className="input-group">
									<input
										type="number"
										className="form-control form-control-solid"
										placeholder="Enter The Contract Total Cost"
										name="contract_cost"
										min="0"
										onChange={(e) => settotalCost(e.target.value)}
									/>

									{/* {totalCost && isbalancevaild &&
										<div className="btn btn-icon btn-light">

											<i className="balance-vaild-icon bi bi-check"></i>

										</div>
									}
									{totalCost && !isbalancevaild &&
										<div className="btn btn-icon btn-light">
											<i className="low-balance-iocn bi bi-exclamation-triangle"></i>


										</div>
									} */}


								</div>


							</div>

						</div>
					</div>









					<div className="d-flex flex-column mb-5 fv-row">

						<label className="required fs-5 fw-bold mb-2">Project Description</label>

						<textarea
							className="form-control form-control-solid"
							rows={3} name="description"
							placeholder="Describe your Project (be as specific as possible)"
							onChange={(e) => setprojectDescription(e.target.value)}></textarea>

					</div>


					<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
						<div className="col">
							<label className={milestonebased === 'nomilestone' ? 'btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5 active' : 'btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5'}>

								<div className="d-flex align-items-center me-2">

									<div className="form-check form-check-custom form-check-solid form-check-primary me-6">
										<input onChange={(e) => setmilestonebased(e.target.value)} className="form-check-input" checked={milestonebased === 'nomilestone'} type="radio" name="plan" value="nomilestone" />
									</div>



									<div className="flex-grow-1">
										<h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
											Full payment
										</h2>

									</div>

								</div>





							</label>

						</div>
						<div className="col">
							<label className={milestonebased === 'milestonebased' ? 'btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5 active' : 'btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5'}>

								<div className="d-flex align-items-center me-2">

									<div className="form-check form-check-custom form-check-solid form-check-primary me-6">
										<input onChange={(e) => { setmilestonebased(e.target.value); scrollToBottom() }} checked={milestonebased === 'milestonebased'} className="form-check-input" type="radio" name="plan" value="milestonebased" />
									</div>



									<div className="flex-grow-1">
										<h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
											Partial payment
										</h2>

									</div>

								</div>

							</label>
						</div>
					</div>



					{milestonebased === 'milestonebased' ?
						// <div className="fv-row mb-8">
						// 	<h4 className="text-primary" style={{ textAlign: "right" }}>This feature will be enabled shortly</h4>
						// </div>
						<div className="fv-row mb-8">

							<label className="required fs-5 fw-bold mb-2">Milestones</label>
							<div className="card card-xl-stretch mb-xl-8">



								<div className="card-body py-3">

									<div className="table-responsive">

										<table className="table align-middle gs-0 gy-3">
											<thead>
												<tr>
													<th className="p-0 min-w-150px">Milestone No</th>
													<th className="p-0 min-w-150px">Milestone Title</th>
													<th className="p-0 min-w-140px">Milestone Description</th>
													<th className="p-0 min-w-120px">Cost</th>
													<th className="p-0 min-w-40px">Duedate</th>
												</tr>
											</thead>


											<tbody>
												{inputFields.map((input, index) => {
													return (
														<tr key={index}>
															<td>
																<input
																	type="text"
																	className="form-control form-control-solid"
																	value={index + 1}
																	name="mileStoneNo"
																	disabled
																	onChange={event => handleFormChange(index, event)} />
															</td>
															<td>
																<input
																	type="text"
																	className="form-control form-control-solid"
																	placeholder="Enter Title"
																	value={input.mileStoneTitle}
																	name="mileStoneTitle"
																	onChange={event => handleFormChange(index, event)} />
															</td>
															<td className="text-end text-muted fw-bold">
																<input
																	type="text"
																	className="form-control form-control-solid"
																	placeholder="Enter Description"
																	value={input.mileStoneDescription}
																	name="mileStoneDescription"
																	onChange={event => handleFormChange(index, event)} />
															</td>
															<td className="text-end text-muted fw-bold">
																<input
																	type="number"
																	className="form-control form-control-solid"
																	placeholder="Enter Cost"
																	value={input.amount}
																	name="amount"
																	onChange={event => handleFormChange(index, event, 'cost')} />
															</td>
															<td className="text-end text-dark fw-bolder fs-6 pe-0">
																<DatePicker
																	minDate={moment().toDate()}
																	className="form-control form-control-solid"
																	selected={input.dueDateString}
																	onChange={(date: Date) => handleFormChange(index, date, 'date')} />

															</td>

															<td><a href={void (0)} className="btn btn-sm btn-danger mt-5" onClick={() => removeFields(index)}>Remove</a></td>
														</tr>
													)
												})}
												<tr>
													<td><a href={void (0)} className="btn btn-sm btn-primary mt-5" onClick={addFields}>Add Milestone</a>;</td>
												</tr>

											</tbody>

										</table>

									</div>

								</div>

							</div>


						</div>
						:

						<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
							<div className="col">
								<div className="d-flex flex-column mb-8 fv-row">

									<label className="d-flex align-items-center fs-6 fw-bold mb-2">
										<span className="required">Due Date</span>
									</label>
									<DatePicker className="form-control form-control-solid" minDate={moment().toDate()} selected={dueDate} onChange={(date: Date) => setdueDate(date)} />
								</div>
							</div>
						</div>}


					{(milestonebased != 'milestonebased') && totalCost && totalcontractCost ?
						<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-8">
							<div className="d-flex flex-stack flex-grow-1">
								<div className="fw-bold">
									<h4 className="text-gray-900 fw-bolder">Total Cost For Creating Contract : {totalcontractCost.toFixed(5)}</h4>
								</div>

							</div>

						</div>
						: ''}
					{(milestonebased == 'milestonebased') && totalcontractCost && inputFields.length > 0 && inputFields[0].amount ?
						<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-8">
							<div className="d-flex flex-stack flex-grow-1">
								<div className="fw-bold">
									<h4 className="text-gray-900 fw-bolder">Total Cost For Creating Contract : {totalcontractCost.toFixed(5)}</h4>
								</div>

							</div>

						</div>
						: ''}




					<div className="text-center">
						<button type="reset" className="btn btn-light me-3" onClick={handleClose}>Cancel</button>
						<button onClick={handleSubmit} type="submit" className="btn btn-primary" data-kt-modal-action-type="submit">
							<span className="indicator-label">Create Contract</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
						</button>
					</div>

				</form>
			</Modal.Body>

		</Modal>

	</>);
};