import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Wallet } from "./Wallet";
import { Chat } from "./pages/chat";
import { ProjectList } from "./pages/project-list";
import { ProjectDetails } from "./pages/project-details";
import { ProjectMilestone } from "./pages/project-milestone";
import { ProjectFiles } from "./pages/project-files";
import { ProjectFeedback } from "./pages/project-feedback";
import { Transactions } from "./pages/transactions";
import { Dashboard } from "./pages/dashboard";
import { Aside } from "./components/aside";
import { Header } from "./components/header";
import { CreateProejctModal } from "./components/create-project-modal";
import { ProfileUpdateModal } from "./components/profile-update-modal";
import { Login } from "./pages/login";
import { Notifications } from "./pages/notification";
import { ResolutionDetails } from "./pages/resolution-detail";

import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  Keypair, sendAndConfirmTransaction
} from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";
import { ResolutionManagement } from "./pages/resolution-management";

import socketIOClient from "socket.io-client";
import { SupportTicket } from "./pages/support-ticket";
const ENDPOINT = "http://notification.thevault-x.com:9096";

export interface RoutesProps {
  connection: anchor.web3.Connection;
  provider: any;
}
type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";


interface ConnectOpts {
  onlyIfTrusted: boolean;
}
interface PhantomProvider {
  publicKey: PublicKey;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAndSendTransaction: (transaction: Transaction) => Promise<any>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}
const getProvider = (): PhantomProvider | undefined => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom) {
      // console.log("provider:"+ provider)
      return provider;
    }
  }
  // window.open("https://phantom.app/", "_blank");
};



const AppRoutes: FC = () => {

  const provider = getProvider();
  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();
  const [createProject, setcreateProject] = useState(false);
  const [socket, setSocket] = useState(null);
  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  useEffect(() => {
    setSocket(socketIOClient(ENDPOINT));
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {wallet && provider && provider.isConnected ?
          <div className="page d-flex flex-row flex-column-fluid">

            <Aside />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header createProject={createProject} socket={socket} setcreateProject={setcreateProject} />
              <CreateProejctModal createProject={createProject} socket={socket} setcreateProject={setcreateProject} />

              <Routes>
                <Route path="/chat" element={<Chat />} />
                <Route path="/my-project" element={<ProjectList />} />
                <Route path="/project-milestone/:id" element={<ProjectMilestone socket={socket} />} />
                <Route path="/project-details/:id" element={<ProjectDetails />} />
                <Route path="/project-files/:id" element={<ProjectFiles />} />
                <Route path="/project-feedback/:id" element={<ProjectFeedback />} />
                <Route path="/project-details/:id" element={<ProjectDetails />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/resolution-management" element={<ResolutionManagement socket={socket} />} />
                <Route path="/resolution-detail/:id/:milestone_id" element={<ResolutionDetails socket={socket} />} />
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/notification" element={<Notifications />} />
                <Route path="/" element={<Dashboard />} />
                {/* <Route path="/page-2" component={Page2}/>
            <Route path="/page-3" component={Page3}/>
            <Route component={NotFound}/> */}
              </Routes>
            </div></div> : <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <Login />
          </div>
        }
        {/* {!wallet && <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <Login />
                </div>} */}

      </div>

    </>
  )
}

export { AppRoutes }