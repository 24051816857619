import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./project-list.css";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
export const ProjectList: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [account, setAccount] = useState(null);
    const [totalContract, settotalContract] = useState(0)
    const [buyerContract, setbuyerContract] = useState([])
    const [sellerContract, setsellerContract] = useState([])
    const { connection } = useConnection();
    const { publicKey } = useWallet();
    const navigate = useNavigate();
    const makeAPICall = async () => {
        console.log("fff")
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'getAllContracts/' + publicKey.toString(), { mode: 'cors' });
            const data = await response.json();
            settotalContract(data['buyerContracts'].length + data['sellerContracts'].length)
            setbuyerContract(data['buyerContracts'].reverse())
            let seller_data = data['sellerContracts'].filter(item => item['progress'] !== 'DRAFT')
            setsellerContract(seller_data.reverse())
            setIsLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    }



    if (publicKey) {
        console.log(publicKey.toString())
        // makeAPICall()
    }


    const init = useCallback(async () => {
        if (publicKey) {
            let acc = await connection.getAccountInfo(publicKey);
            setAccount(acc);

        }
    }, [publicKey, connection]);


    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         makeAPICall();
    //       }, 5000);

    // }, [])
    // handleChange = param => e => {
    let call_project_details = param => e => {
        console.log(param)
        localStorage.setItem('project_id', param)
        // window.location.href = '/project-milestone/'+param;
        navigate('/project-milestone/' + param);
    }
    useEffect(() => {
        setIsLoading(true)
        if (publicKey) {
            localStorage.setItem('p_key', publicKey.toString())
            makeAPICall();
            //   setInterval(init, 1000);
        }
    }, [init, publicKey]);

    const gettime = (timestamp) => {
        var stillUtc = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss a').toDate();
        var local = moment(stillUtc, 'YYYY-MM-DD HH:mm:ss a').local().format('MMMM Do YYYY, h:mm a')

        return local;
    }

    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    return (<>
        {isLoading && <Loader />}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">
                <div className="row g-5 g-xl-8">

                    <div className="col-xl-12">
                        <div className="card mb-5 mb-xl-8">

                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bolder fs-3 mb-1">My Projects</span>
                                    <span className="text-muted mt-1 fw-bold fs-7">Total of {totalContract} Projects</span>
                                </h3>

                            </div>


                            <div className="card-body py-3">
                                <ul className="nav nav-pills me-6 mb-6 mb-sm-0 project-tab">
                                    <li className="nav-item m-0">
                                        <a className="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 active" data-bs-toggle="tab" href="#kt_project_users_card_pane">

                                            View as a Buyer

                                        </a>
                                    </li>
                                    <li className="nav-item m-0">
                                        <a className="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary" data-bs-toggle="tab" href="#kt_project_users_table_pane">
                                            View as a Seller
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content mt-8">

                                    <div id="kt_project_users_card_pane" className="tab-pane fade active show">
                                        <div className="table-responsive">

                                            <table className="table align-middle gs-0 gy-4">

                                                <thead>
                                                    <tr className="fw-bolder text-muted bg-light">
                                                        <th className="min-w-10px">#</th>
                                                        <th className="ps-4 min-w-300px rounded-start">Project title</th>
                                                        <th className="min-w-125px">Status</th>
                                                        <th className="min-w-125px">Cost (SOL)</th>
                                                        <th className="min-w-150px">Created On</th>
                                                        <th className="min-w-200px text-end rounded-end"></th>
                                                    </tr>
                                                </thead>


                                                {buyerContract.length > 0 && (
                                                    <tbody>
                                                        {buyerContract.map((contract, index) => (
                                                            <tr key={contract.projectId}>
                                                                <td className="text-dark fw-bolder">{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">

                                                                        <div className="d-flex justify-content-start flex-column">
                                                                            <a href="#" onClick={call_project_details(contract.projectId)} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{contract.projectTitle}</a>
                                                                            {/* <span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" onClick={call_project_details(contract.projectId)} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{contract.progress}</a>
                                                                    {/* <span className="text-muted fw-bold text-muted d-block fs-7">Pending</span> */}
                                                                </td>
                                                                <td>
                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{contract.totalAmount}◎</a>
                                                                    {/* <span className="text-muted fw-bold text-muted d-block fs-7">Paid</span> */}
                                                                </td>
                                                                <td>
                                                                    <span className="text-dark fw-bolder">{gettime(contract.createdTimeStamp)}</span>
                                                                </td>
                                                                {/* <td className="text-end">
                                                           <div className="d-flex flex-column w-100 me-2">
                                                               <div className="d-flex flex-stack mb-2">
                                                                   <span className="text-muted me-2 fs-7 fw-bold">{contract['progress'] == 'COMPLETED' ? '100%' : '10%'}</span>
                                                               </div>
                                                               <div className="progress h-6px w-100">
                                                               <div className={contract['progress'] == 'COMPLETED' ? "progress-bar bg-primary" : "progress-bar bg-success"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "20%" }} ></div>
                                                               </div>
                                                           </div>
                                                       </td> */}
                                                                <td className="text-end">
                                                                    <span onClick={call_project_details(contract.projectId)} className="btn btn-view-project btn-sm px-4 me-2">View</span>
                                                                    {/* <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}




                                            </table>

                                        </div>
                                    </div>

                                    <div id="kt_project_users_table_pane" className="tab-pane fade">
                                        <div className="table-responsive">

                                            <table className="table align-middle gs-0 gy-4">

                                                <thead>
                                                    <tr className="fw-bolder text-muted bg-light">
                                                        <th className="min-w-10px">#</th>
                                                        <th className="ps-4 min-w-300px rounded-start">Project title</th>
                                                        <th className="min-w-125px">Status</th>
                                                        <th className="min-w-125px">Cost (SOL)</th>
                                                        <th className="min-w-150px">Created On</th>
                                                        <th className="min-w-200px text-end rounded-end"></th>
                                                    </tr>
                                                </thead>
                                                {sellerContract.length > 0 && (
                                                    <tbody>
                                                        {sellerContract.reverse().map((contract, index) => (

                                                            <tr key={contract.projectId}>
                                                                <td className="text-dark fw-bolder">{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">

                                                                        <div className="d-flex justify-content-start flex-column">
                                                                            <a href="#" onClick={call_project_details(contract.projectId)} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{contract.projectTitle}</a>
                                                                            {/* <span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{contract.progress}</a>
                                                                    {/* <span className="text-muted fw-bold text-muted d-block fs-7">Pending</span> */}
                                                                </td>
                                                                <td>
                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{contract.totalAmount}◎</a>
                                                                    {/* <span className="text-muted fw-bold text-muted d-block fs-7">Paid</span> */}
                                                                </td>
                                                                <td>
                                                                    <span className="text-dark fw-bolder">{gettime(contract.createdTimeStamp)}</span>

                                                                </td>

                                                                {/* <td className="text-end">
                                                           <div className="d-flex flex-column w-100 me-2">
                                                               <div className="d-flex flex-stack mb-2">
                                                                   <span className="text-muted me-2 fs-7 fw-bold">{contract['progress'] == 'COMPLETED' ? '100%' : '30%'}</span>
                                                               </div>
                                                               <div className="progress h-6px w-100">
                                                               
                                                                   <div className={contract['progress'] == 'COMPLETED' ? "progress-bar bg-primary" : "progress-bar bg-success"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "30%" }} ></div>
                                                               </div>
                                                           </div>
                                                       </td> */}
                                                                <td className="text-end">
                                                                    <a href="#" onClick={call_project_details(contract.projectId)} className="btn btn-view-project btn-sm px-4 me-2">View</a>
                                                                    {/* <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}



                                                {/* <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-50px me-5">
                                                                    <span className="symbol-label bg-light">
                                                                        <h1>D</h1>
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex justify-content-start flex-column">
                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Discord -custom bot development</a>
                                                                   
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">Completed</a>
                                                          
                                                        </td>
                                                        <td>
                                                            <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">40◎</a>
                                                           
                                                        </td>


                                                        <td className="text-end">
                                                            <div className="d-flex flex-column w-100 me-2">
                                                                <div className="d-flex flex-stack mb-2">
                                                                    <span className="text-muted me-2 fs-7 fw-bold">100%</span>
                                                                </div>
                                                                <div className="progress h-6px w-100">
                                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2">View</a>
                                                            <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-50px me-5">
                                                                    <span className="symbol-label bg-light">
                                                                        <h1>W</h1>
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex justify-content-start flex-column">
                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Website Development</a>
                                                                   
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">In Disbute</a>
                                                       
                                                        </td>
                                                        <td>
                                                            <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">30◎</a>
                                                          
                                                        </td>


                                                        <td className="text-end">
                                                            <div className="d-flex flex-column w-100 me-2">
                                                                <div className="d-flex flex-stack mb-2">
                                                                    <span className="text-muted me-2 fs-7 fw-bold">70%</span>
                                                                </div>
                                                                <div className="progress h-6px w-100">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "70%" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100}></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2">View</a>
                                                            <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a>
                                                        </td>
                                                    </tr>
                                                    */}


                                            </table>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};