import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { Loader } from "../components/loader";
import { useParams } from "react-router-dom";
import "./project-details.css";

import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";


export const ProjectDetails: FC = () => {
    let { id } = useParams();
    const [contract, setcontract] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [current_user_type, setcurrent_user_type] = useState('');
    const navigate = useNavigate();
    const get_project = async () => {
        console.log("fff")
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'getProject/' + localStorage.getItem('project_id') + '/' + localStorage.getItem('p_key'), { mode: 'cors' });
            const data = await response.json();
            if (data.createdBy == 'buyer') {
                if (data.buyerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('buyer')
                } else if (data.sellerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('seller')
                }
            } else {
                if (data.sellerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('buyer')
                } else if (data.buyerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('seller')
                }
            }
            setcontract(data)
            setIsLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        setIsLoading(true)
        // if (publicKey) {
        get_project();
        // exit;
        //   setInterval(init, 1000);
        // }
    }, []);
    console.log(id)
    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    return (<>
        {isLoading && <Loader />}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">

                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                            <div className="me-7 mb-4 project-firstname-container">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    <div className="project-firstname">{'projectTitle' in contract ? contract['projectTitle'][0] : ''}</div>

                                </div>
                            </div>


                            <div className="flex-grow-1">

                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                    <div className="d-flex flex-column">

                                        <div className="d-flex align-items-center mb-2">
                                            <a href="#" className="text-gray-900 text-hover-primary fs-2 me-1">{contract['projectTitle']}</a>

                                        </div>


                                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                            <a href="#" className="d-flex align-items-center  text-hover-primary mb-2">

                                                {current_user_type == 'seller' ? "Buyer" : "Seller"}
                                            </a>:

                                            <a href="#" className="mx-2 d-flex align-items-center  text-hover-primary mb-2" data-toggle="tooltip" title={current_user_type == 'seller' ? contract['buyerAddress'] : contract['sellerAddress']} >
                                                {current_user_type == 'seller' ? (contract['buyerprofileName'] != '' && contract['buyerprofileName'] != null ? contract['buyerprofileName'] : contract['buyerAddress']) : (contract['sellerprofileName'] != '' && contract['sellerprofileName'] != null ? contract['sellerprofileName'] : contract['sellerAddress'])}</a>
                                        </div>

                                    </div>



                                </div>


                                <div className="d-flex flex-wrap flex-stack">

                                    <div className="d-flex flex-column flex-grow-1 pe-8">

                                        <div className="d-flex flex-wrap">

                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">

                                                    {/* <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                    </span> */}

                                                    <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['totalAmount'] != null ? contract['totalAmount'].toFixed(2) : ''}◎</div>
                                                </div>


                                                <div className="fw-bold fs-6">Total Cost</div>

                                            </div>


                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">

                                                    {/* <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                        </svg>
                    </span> */}

                                                    <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['escrowHoldingCost'] != null ? contract['escrowHoldingCost'].toFixed(2) : ''}◎</div>
                                                </div>


                                                <div className="fw-bold fs-6">Escrow Holding</div>

                                            </div>


                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">

                                                    {/* <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                    </span> */}

                                                    <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['totalPaid'] != null ? contract['totalPaid'].toFixed(2) : ''}◎</div>
                                                </div>


                                                <div className="fw-bold fs-6">Total Paid</div>

                                            </div>

                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">
                                                    {/* 
                    <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                    </span> */}

                                                    <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['reaminingCost'] != null ? contract['reaminingCost'].toFixed(2) : ''}◎</div>
                                                </div>


                                                <div className="fw-bold fs-6">Remaining</div>

                                            </div>

                                        </div>

                                    </div>


                                    {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                <span className="fw-bold fs-6">{contract['progress']}</span>
                <span className="fw-bolder fs-6">{contract['progress'] == 'COMPLETED' ? 100 : ''}</span>
            </div>
            <div className="h-5px mx-3 w-100 bg-light mb-3">
                <div className={contract['progress'] == 'COMPLETED' ? "bg-primary rounded h-5px" : "bg-success rounded h-5px"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "30%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
            </div>
        </div> */}

                                </div>

                            </div>

                        </div>


                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">

                            <li className="nav-item mt-2">
                                <Link className="nav-link text-active-primary ms-0 me-10 py-5" to={{ pathname: "/project-milestone/" + contract['projectId'] }}>Milestones</Link>
                            </li>


                            <li className="nav-item mt-2">
                                <Link className="nav-link text-active-primary ms-0 me-10 py-5 active" to={{ pathname: "/project-details/" + contract['projectId'] }}>Description and Terms</Link>
                            </li>


                            <li className="nav-item mt-2">
                                <Link className="nav-link text-active-primary ms-0 me-10 py-5" to={{ pathname: "/project-files/" + contract['projectId'] }}>Files</Link>
                            </li>


                            <li className="nav-item mt-2">
                                <Link className="nav-link text-active-primary ms-0 me-10 py-5" to={{ pathname: "/project-feedback/" + contract['projectId'] }}>Feedback</Link>
                            </li>




                        </ul>

                    </div>
                </div>

                <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                    <div className="card-header cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Profile Description and Terms</h3>
                        </div>


                    </div>
                    <div className="card-body p-9">
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">

                            <div className="d-flex flex-stack flex-grow-1">

                                <div className="fw-bold">
                                    <h4 className="text-gray-900 fw-bolder">{contract['projectDescription']}</h4>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>






            </div>
        </div>
    </>);
};