import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { UserModel } from "../models/userModel";
import moment from "moment";
import { chatdetailsModel } from "../models/chatdetailsModel";
import { chatMessagesModel } from "../models/chatMessagesModel";
import { MessageRequestModel } from "../models/messageRequestModel";
import { ChatNotificationstModel } from "../models/chatNotificationsModel";
import blue_tick from "../images/bluetick.png";
import grey_tick from "../images/greytick.png";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Tabs, Tab, Button, Spinner, Badge } from "react-bootstrap";

import { Loader } from "../components/loader";
import Swal from "sweetalert2";
import "./chat.css";

const axios = require("axios");
const socketIOClient = require("socket.io-client");
const ENDPOINT = process.env.REACT_APP_SIGNALING_SERVER_URL;
const api_base_url_chat = process.env.REACT_APP_CHAT_API_URL;
const api_url_main = process.env.REACT_APP_API_URL;

let socket;

export const Chat: FC = () => {
  const { publicKey } = useWallet();
  const [message, setMessage] = useState("");
  const [userId, setuserId] = useState("");
  const [UserTypingDetails, setUserTypingDetails] = useState({
    fromid: "",
    istyping: false,
  });
  const [profile, setprofile] = useState("");
  const [token, setToken] = useState("");
  const [toUser, setToUser] = useState<chatdetailsModel>();
  const [userList, setUserList] = useState<UserModel[]>();
  const [OriginalUserList, setOriginalUserList] = useState<UserModel[]>();
  const [relation, setrelataion] = useState({});
  const [requestMessageTo, setrequestMessageTo] =
    useState<MessageRequestModel>();
  const [messRequestUser, setMessRequestUser] = useState(null);
  const [addRetalionList, setAddRetalionList] =
    useState<MessageRequestModel[]>();
  const [OriginalAddRetalionList, setOriginalAddRetalionList] =
    useState<MessageRequestModel[]>();
  const [sentMessReqestList, setSentMessReqestList] =
    useState<MessageRequestModel[]>();
  const [originalSentMessReqestList, setOriginalSentMessReqestList] =
    useState<MessageRequestModel[]>();
  const [chatNotifications, setChatNotifications] =
    useState<ChatNotificationstModel>(new ChatNotificationstModel());

  const [activeTabKey, setActiveTabKey] = useState("inbox");

  const [isLoading, setIsLoading] = useState(false);
  const [isUserListLoading, setUserListLoading] = useState(true);
  const [isMessageRequestLoading, setIsMessageRequestLoading] = useState(true);
  const [isMessageRequestSentLoading, setIsMessageRequestSentLoading] =
    useState(true);

  const prevToUser = useRef<chatdetailsModel>();
  const prevRelation = useRef({});
  const prevprofile = useRef("");
  const messagesEndRef = useRef(null);
  const sendButtonRef = useRef(null);

  const formatDateTime = (inputDatetime: string) => {
    if (!inputDatetime) {
      return null;
    }

    var local_time = moment
      .utc(inputDatetime)
      .local()
      .format("YYYY-MM-DD HH:mm:ss");

    let currentDateTime = new Date();
    var differenceInHours = moment(currentDateTime).diff(
      moment(local_time),
      "hours"
    );

    if (differenceInHours <= 24) {
      return moment(local_time).format("hh:mm A");
    }
    if (differenceInHours <= 48) {
      return "Yesterday";
    } else {
      return (
        moment(currentDateTime).diff(moment(local_time), "days") + " days ago"
      );
    }
  };

  const get_profile = async () => {
    try {
      const response = await fetch(
        api_url_main + "getProfile/" + publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      console.log("profile resp:", data);
      let _profileName =
        data["profileName"] == null
          ? data["walletAddress"].substring(0, 4)
          : data["profileName"];
      setprofile(_profileName);
      prevprofile.current = _profileName;
    } catch (e) {
      console.log(e);
    }
  };

  const login = async () => {
    try {
      let login_req = {
        walletid: userId,
        dname: "",
        socketid: "",
        mode: "login",
      };
      let resp = await axios.post(
        api_base_url_chat + "/api/auth/login",
        login_req
      );
      if (
        resp &&
        resp.data &&
        resp.data["data"] &&
        resp.data["data"].length > 0
      ) {
        setToken(resp.data["data"][0]["token"]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const get_relation = async () => {
    try {
      const response = await fetch(
        api_url_main + "getAllConnections/" + publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      let temp = "";
      data["allConnections"].forEach((element, index) => {
        if (element && element["walletAddress"]) {
          if (index == data["allConnections"].length - 1) {
            temp += element["walletAddress"];
          } else {
            temp += element["walletAddress"] + ",";
          }
        }
      });
      console.log("relation resp:", temp);
      if (temp == "") {
        temp = "N/A";
      }
      setrelataion(temp);
      prevRelation.current = temp;
    } catch (e) {
      console.log(e);
    }
  };

  const addRelation = async () => {
    try {
      if (prevRelation.current && prevRelation.current != "N/A") {
        let addRelation_req = {
          walletid: userId,
          dname: "",
          socketid: "",
          relationuserid: prevRelation.current,
          mode: "add-relation",
        };
        console.log("adding relation:", addRelation_req);
        let resp = await axios.post(
          api_base_url_chat + "/api/chat/add-relations",
          addRelation_req,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        fetchUsers();
        getNotificationCounts();
      } else {
        fetchUsers();
        getNotificationCounts();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUsers = async () => {
    let fetchUsers_req = {
      userid_from: userId,
      userid_to: "",
      message: "",
      mode: "fetch",
    };
    console.log("fetch users req:", fetchUsers_req);
    await axios
      .post(api_base_url_chat + "/api/chat/fetch-users", fetchUsers_req, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((resp: { data: { data: string | any[] } }) => {
        let _userlist = [];
        for (let i = 0; i < resp.data.data.length; i++) {
          _userlist.push(
            new UserModel(
              resp.data.data[i].DisplayName?.charAt(0).toUpperCase(),
              resp.data.data[i].UniqueUserID,
              resp.data.data[i].DisplayName,
              resp.data.data[i].lastMessage
                ? resp.data.data[i].lastMessage
                : "",
              resp.data.data[i].lastMessageAt,
              resp.data.data[i].isOnline === "Y"
            )
          );
        }
        setUserList(_userlist);
        setOriginalUserList(_userlist);
        setUserListLoading(false);
        if (prevToUser.current && prevToUser.current.walletid) {
          let _touser = new chatdetailsModel(
            prevToUser.current.title,
            prevToUser.current.walletid,
            prevToUser.current.name,
            _userlist[
              _userlist.findIndex(
                (i) => i.walletid === prevToUser.current.walletid
              )
            ]["is_active"],
            true,
            prevToUser.current.chat_messages
          );
          setToUser(_touser);
          prevToUser.current = _touser;
        }
      })
      .catch((err: any) => {
        console.log(err);
        setUserListLoading(false);
      });
  };

  const getNotificationCounts = async () => {
    try {
      let notify_req = {
        walletid: userId,
        dname: "",
        socketid: "",
        relationuserid: "",
        mode: "get-notification-count",
      };
      let resp = await axios.post(
        api_base_url_chat + "/api/chat/fetch-notification-count",
        notify_req,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      let notifications = new ChatNotificationstModel();
      if (resp.data && resp.data.data && resp.data.data.length > 0) {
        notifications.inboxCount = resp.data.data[0].inbox;
        notifications.sentRequestCount = resp.data.data[0].sentrequest;
        notifications.receivedRequestCount = resp.data.data[0].receivedrequest;
      }
      setChatNotifications(notifications);
    } catch (err) {
      console.error(err);
    }
  };

  const getAddRelationRequests = async () => {
    setIsMessageRequestLoading(true);
    try {
      let addRelation_req = {
        walletid: userId,
        dname: "",
        socketid: "",
        relationuserid: "",
        mode: "get-add-relation-request",
      };
      let resp = await axios.post(
        api_base_url_chat + "/api/chat/fetch-add-relation-request",
        addRelation_req,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      //console.log("get add relation requests resp...", resp.data.data);

      let message_request_list = [];
      for (let i = 0; i < resp.data.data.length; i++) {
        message_request_list.push(
          new MessageRequestModel(
            resp.data.data[i].DisplayName?.charAt(0).toUpperCase(),
            resp.data.data[i].UniqueUserID,
            resp.data.data[i].DisplayName,
            resp.data.data[i].MessRequestOn,
            resp.data.data[i].isOnline === "Y"
          )
        );
      }

      setAddRetalionList(message_request_list);
      setOriginalAddRetalionList(message_request_list);
      setIsMessageRequestLoading(false);
    } catch (err) {
      console.error(err);
      setIsMessageRequestLoading(false);
    }
  };

  const getSentMesssageRequests = async () => {
    setIsMessageRequestSentLoading(true);
    try {
      let addRelation_req = {
        walletid: userId,
        dname: "",
        socketid: "",
        relationuserid: "",
        mode: "sent-add-relation-request",
      };
      let resp = await axios.post(
        api_base_url_chat + "/api/chat/fetch-add-relation-request",
        addRelation_req,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("sent mess resp", resp.data);
      let message_request_list = [];
      for (let i = 0; i < resp.data.data.length; i++) {
        message_request_list.push(
          new MessageRequestModel(
            resp.data.data[i].DisplayName?.charAt(0).toUpperCase(),
            resp.data.data[i].UniqueUserID,
            resp.data.data[i].DisplayName,
            resp.data.data[i].MessRequestOn,
            resp.data.data[i].isOnline === "Y"
          )
        );
      }

      setSentMessReqestList(message_request_list);
      setOriginalSentMessReqestList(message_request_list);
      setIsMessageRequestSentLoading(false);
    } catch (err) {
      console.error(err);
      setIsMessageRequestSentLoading(false);
    }
  };

  const verifyWalletAddress = async () => {
    try {
      //console.log("verify wallet address..", messRequestUser);
      const response = await axios.get(
        api_url_main + "validateAddress/" + messRequestUser.toString()
      );
      //console.log("verify wallet address resp", response);
      return response && response.status === 200 && response.data == "success";
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const sendMessageRequest = async (e) => {
    if (!messRequestUser) {
      return Swal.fire(
        "Validation Error",
        "Enter wallet id to send message request",
        "error"
      );
    }
    e.preventDefault();
    setIsLoading(true);
    if (await verifyWalletAddress()) {
      //console.log("sending mess request..", messRequestUser);
      try {
        let addRelation_req = {
          walletid: userId,
          dname: "",
          socketid: "",
          relationuserid: messRequestUser,
          mode: "add-relation-request",
        };
        // console.log("add relation req", addRelation_req);
        let resp = await axios.post(
          api_base_url_chat + "/api/chat/add-relation-request",
          addRelation_req,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        //console.log("add relation request resp", resp.data.data);
        setMessRequestUser("");
        setIsLoading(false);
        if (resp.data.data && resp.data.data.length > 0) {
          if (resp.data.data[0]["status"] === 1) {
            socket.emit("updaddrequest", {
              toid: "usr-" + messRequestUser,
              fromid: "usr-" + userId,
              mess: "",
            });
            Swal.fire(resp.data.data[0]["message"], "", "success");
            getNotificationCounts();
            getSentMesssageRequests();
          } else Swal.fire(resp.data.data[0]["message"], "", "error");
        } else {
          Swal.fire("Sent message request failed", "", "error");
        }
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    } else {
      Swal.fire("Wallet id is not valid", "", "error");
      setIsLoading(false);
    }
  };

  const updateMessageRequest = async (item: MessageRequestModel) => {
    setIsLoading(true);
    try {
      let addRelation_req = {
        walletid: userId,
        dname: "",
        socketid: "",
        relationuserid: item.walletid,
        mode:
          item.status == "A"
            ? "accept-relation-request"
            : "reject-relation-request",
      };
      let resp = await axios.post(
        api_base_url_chat + "/api/chat/update-relation-request",
        addRelation_req,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      //console.log("update relation request resp", resp.data.data);
      setIsLoading(false);
      if (resp.data.data && resp.data.data.length > 0) {
        resp.data.data[0]["message"] == "relation request accepted"
          ? Swal.fire("Request accepted successfully", "", "success")
          : Swal.fire("Request accepted failed", "", "error");

        socket.emit("updaddrequest", {
          toid: "usr-" + item.walletid,
          fromid: "usr-" + userId,
          mess: "",
        });
        getNotificationCounts();
      } else {
        Swal.fire("Request accepted failed", "", "error");
      }
      setrequestMessageTo(null);
      fetchUsers();
      setActiveTabKey("inbox");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const sendMessage = async () => {
    try {
      if (userId && toUser.walletid && message) {
        let message_req = {
          userid_from: userId,
          userid_to: toUser.walletid,
          message: message,
          mode: "insert",
        };
        let resp = await axios.post(
          api_base_url_chat + "/api/chat/add-msg",
          message_req,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (socket && userId && toUser.walletid && message) {
          socket.emit("sendmess", {
            fromid: "usr-" + userId,
            toid: "usr-" + toUser.walletid,
            mess: message,
          });
        }
        setMessage("");
        getMessage(true);
        fetchUsers();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getMessage = async (scroll: boolean) => {
    try {
      if (userId && prevToUser.current && prevToUser.current.walletid) {
        let messages_req = {
          userid_from: userId,
          userid_to: prevToUser.current.walletid,
          message: "",
          mode: "fetch-chat-details",
        };
        //console.log("getmessages req", messages_req);
        let resp = await axios.post(
          api_base_url_chat + "/api/chat/fetch-users-chat",
          messages_req,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (prevToUser.current.chat_messages) {
          let _chatmessageDetails = [];
          for (let i = 0; i < resp.data["data"].length; i++) {
            _chatmessageDetails.push(
              new chatMessagesModel(
                resp.data["data"][i]["FromUserID"],
                resp.data["data"][i]["ToUserID"],
                resp.data["data"][i]["Message"],
                resp.data["data"][i]["SentTime"],
                "",
                resp.data["data"][i]["ReadStatus"],
                "",
                resp.data["data"][i]["FromUser"],
                resp.data["data"][i]["ToUser"]
              )
            );
          }
          let _touser = new chatdetailsModel(
            prevToUser.current.title,
            prevToUser.current.walletid,
            prevToUser.current.name,
            prevToUser.current.is_active,
            true,
            _chatmessageDetails
          );
          setToUser(_touser);
          prevToUser.current = _touser;
          if (scroll) scrollToBottom();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateRead = async () => {
    try {
      if (userId && prevToUser.current && prevToUser.current.walletid) {
        let upd_read_req = {
          userid_from: userId,
          userid_to: prevToUser.current.walletid,
          message: "",
          mode: "update-read-mess",
        };
        let resp = await axios.post(
          api_base_url_chat + "/api/chat/update-read-mess",
          upd_read_req,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        socket.emit("updread", {
          toid: "usr-" + prevToUser.current.walletid,
          fromid: "usr-" + userId,
          mess: "",
        });

        getNotificationCounts();
      }
    } catch (err) {
      console.error(err);
    }
  };

  function _filterUser(event: React.ChangeEvent<HTMLInputElement>): void {
    let value = event.target.value.toLowerCase();
    if (!value) {
      setUserList(OriginalUserList);
    } else {
      let result = [];
      result =
        OriginalUserList == undefined
          ? []
          : OriginalUserList.filter((data) => {
              return (
                data.name.toLowerCase().search(value.toLocaleLowerCase()) !=
                  -1 ||
                data.walletid.toLowerCase().search(value.toLocaleLowerCase()) !=
                  -1
              );
            });
      setUserList(result);
    }
  }

  function _filterRequestUser(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value.toLowerCase();
    if (!value) {
      setAddRetalionList(OriginalAddRetalionList);
    } else {
      let result = [];
      result =
        OriginalAddRetalionList == undefined
          ? []
          : OriginalAddRetalionList.filter((data) => {
              return (
                data.name.toLowerCase().search(value.toLocaleLowerCase()) != -1
              );
            });
      setAddRetalionList(result);
    }
  }

  function _filterRequestUserSent(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value.toLowerCase();
    if (!value) {
      setSentMessReqestList(originalSentMessReqestList);
    } else {
      let result = [];
      result =
        originalSentMessReqestList == undefined
          ? []
          : originalSentMessReqestList.filter((data) => {
              return (
                data.name.toLowerCase().search(value.toLocaleLowerCase()) != -1
              );
            });
      setSentMessReqestList(result);
    }
  }

  function goToChatDetails(item: UserModel) {
    let _touser = new chatdetailsModel(
      item.title,
      item.walletid,
      item.name,
      item.is_active
    );
    setToUser(_touser);
    prevToUser.current = _touser;
    setMessage("");
  }

  function goToMessageReqDetails(item: MessageRequestModel) {
    setToUser(null);
    prevToUser.current = null;
    setrequestMessageTo(item);
  }

  function acceptRequest(item: MessageRequestModel) {
    item.status = "A";
    updateMessageRequest(item);
  }

  function rejectRequest(item: MessageRequestModel) {
    item.status = "R";
    updateMessageRequest(item);
  }

  const send = () => {
    if (sendButtonRef.current) {
      sendButtonRef.current.setAttribute("disabled", true);
    }
    sendMessage();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    updateRead();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const sendTypingNotification = (e) => {
    if (e.which != 13) {
      if (socket && userId && toUser.walletid) {
        socket.emit("typing", {
          fromid: userId,
          toid: "usr-" + toUser.walletid,
          typing: true,
        });
        setTimeout(() => {
          if (socket && userId && toUser.walletid) {
            socket.emit("typing", {
              fromid: userId,
              toid: "usr-" + toUser.walletid,
              typing: false,
            });
          }
        }, 3000);
      }
    }
  };

  const handleTabSelect = (key) => {
    // console.log("active tab", key);
    setActiveTabKey(key);
    setToUser(null);
    prevToUser.current = null;
    setrequestMessageTo(null);
    switch (key) {
      case "inbox":
        fetchUsers();
        break;
      case "message-request":
        getAddRelationRequests();
        break;
      case "send-message-request":
        getSentMesssageRequests();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("public key:", publicKey);
    if (publicKey) {
      get_profile();
    }
  }, [publicKey]);

  useEffect(() => {
    console.log("profile:", profile);
    if (profile) {
      get_relation();
    }
  }, [profile]);

  useEffect(() => {
    console.log("relation:", prevRelation.current);
    if (
      prevRelation.current &&
      Object.keys(prevRelation.current).length > 0 &&
      publicKey
    ) {
      // console.log(
      //   "step 3: relation, publickey",
      //   prevRelation.current,
      //   publicKey
      // );
      setuserId(publicKey.toString());
    }
  }, [relation]);

  useEffect(() => {
    console.log("userid:", userId);
    if (userId) {
      login();
    }
  }, [userId]);

  useEffect(() => {
    console.log("token:", token);
    if (token) {
      socket = socketIOClient(ENDPOINT, {
        reconnectionAttempts: 3,
        rejectUnauthorized: false,
      });

      socket.on("connect", () => {
        socket.emit("join", {
          userid: "usr-" + userId,
          dname: prevprofile.current,
        });
      });

      socket.on("connect_error", function (err: { message: any }) {
        console.log(`connect_error due to ${err.message}`);
      });

      socket.on("disconnect", () => {});

      socket.on("users", (data) => {
        addRelation();
      });

      socket.on("userdisconnect", (data) => {
        fetchUsers();
      });

      socket.on("newmsg", (mess: any) => {
        fetchUsers();
        getMessage(true);
        getNotificationCounts();
      });

      socket.on("readstatus", (status: any) => {
        getMessage(false);
      });

      socket.on("addrequeststatus", (status: any) => {
        fetchUsers();
        getNotificationCounts();
        getAddRelationRequests();
        getSentMesssageRequests();
      });

      socket.on("typingevent", (data: any) => {
        setUserTypingDetails(data);
      });
      return () => socket.disconnect();
    }
  }, [token]);

  useEffect(() => {
    console.log("to user:", toUser);
    if (toUser && !toUser.is_messages_loaded) {
      getMessage(true);
    }
  }, [toUser]);

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
              <div className="card card-flush">
                <Tabs
                  defaultActiveKey="inbox"
                  activeKey={activeTabKey}
                  id="tab-chat-1"
                  className="mb-3"
                  onSelect={handleTabSelect}
                >
                  <Tab
                    eventKey="inbox"
                    title={
                      <React.Fragment>
                        Inbox
                        <Badge className="clr-blue">
                          {chatNotifications.inboxCount}
                        </Badge>
                      </React.Fragment>
                    }
                  >
                    <div
                      className="card-header pt-7 px-0"
                      id="kt_chat_contacts_header"
                    >
                      <div className="pb-5 px-10">
                        <h3>All Messages</h3>
                      </div>
                      <form className="w-100 position-relative">
                        <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="currentColor"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>

                        <input
                          type="text"
                          className="form-control form-control-solid px-15"
                          name="search"
                          placeholder="Search by user name or wallet id"
                          onChange={(event) => _filterUser(event)}
                        />
                      </form>
                    </div>

                    <div
                      className="card-body pt-5 px-0"
                      id="kt_chat_contacts_body"
                    >
                      <div
                        className="scroll-y me-n5 pe-5 h-200px h-lg-auto cls-chat-height"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
                        data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
                        data-kt-scroll-offset="5px"
                      >
                        {isUserListLoading ? (
                          <div className="row justify-content-center pt-5">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                          userList?.map((item, index) => {
                            return (
                              <div
                                className="d-flex flex-stack py-4 px-8 cls_chat_list"
                                key={index}
                                onClick={() => goToChatDetails(item)}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-45px symbol-circle">
                                    <span className="symbol-label bg-light-success text-success fs-6 fw-bolder">
                                      {item.title}
                                    </span>
                                    <div
                                      className={
                                        "symbol-badge " +
                                        (item.is_active
                                          ? " bg-success "
                                          : " bg-danger ") +
                                        " start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
                                      }
                                    ></div>
                                  </div>

                                  <div className="ms-5">
                                    <a
                                      href="#"
                                      className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                                    >
                                      {item.name}
                                    </a>

                                    <div className="fw-bold text-muted cls-chat-preview-text">
                                      {UserTypingDetails.fromid ==
                                        item.walletid &&
                                      UserTypingDetails.istyping ? (
                                        <span className="text-success">
                                          Typing...
                                        </span>
                                      ) : (
                                        item.last_message
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-column align-items-end ms-2">
                                  <span className="text-muted fs-7 mb-1">
                                    {formatDateTime(item.last_message_time)}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="message-request"
                    title={
                      <React.Fragment>
                        Requests
                        <Badge className="clr-green">
                          {chatNotifications.receivedRequestCount}
                        </Badge>
                      </React.Fragment>
                    }
                  >
                    <div className="p-2">
                      <input
                        type="text"
                        className="form-control form-control-solid px-15"
                        name="search"
                        placeholder="Search user..."
                        onChange={(event) => _filterRequestUser(event)}
                      />
                    </div>
                    <div className="px-4 py-3">
                      {isMessageRequestLoading ? (
                        <div className="row justify-content-center pt-5">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        addRetalionList?.map((item, index) => {
                          return (
                            <div
                              className="d-flex flex-stack py-2"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => goToMessageReqDetails(item)}
                            >
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-45px symbol-circle">
                                  <span className="symbol-label bg-light-success text-success fs-6 fw-bolder">
                                    {item.title}
                                  </span>
                                  <div
                                    className={
                                      "symbol-badge " +
                                      (item.is_active
                                        ? " bg-success "
                                        : " bg-danger ") +
                                      " start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
                                    }
                                  ></div>
                                </div>

                                <div className="ms-5">
                                  <a
                                    href="#"
                                    className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                                  >
                                    {item.name}
                                  </a>
                                </div>
                              </div>

                              {/* <div className="d-flex flex-column align-items-end ms-2">
                                  <span className="text-muted fs-7 mb-1">
                                    {formatDateTime(item.request_time)}
                                  </span>
                                </div> */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="send-message-request"
                    title={
                      <React.Fragment>
                        Connect Request
                        <Badge className="clr-red">
                          {chatNotifications.sentRequestCount}
                        </Badge>
                      </React.Fragment>
                    }
                  >
                    <>
                      <div className="p-7 pb-0">
                        <div className="d-flex flex-column mb-8 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">
                              Enter Wallet Address
                            </span>
                          </label>

                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Wallet Address"
                            name="wallet_address"
                            value={messRequestUser}
                            onChange={(e) => setMessRequestUser(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="p-7 pt-0 d-flex justify-content-center">
                        <Button variant="primary" onClick={sendMessageRequest}>
                          Send Message Request
                        </Button>
                      </div>
                      <div className="p-3">
                        <input
                          type="text"
                          className="form-control form-control-solid px-15"
                          name="search"
                          placeholder="Search user..."
                          onChange={(event) => _filterRequestUserSent(event)}
                        />
                      </div>
                      <div className="px-4 py-3">
                        {isMessageRequestSentLoading ? (
                          <div className="row justify-content-center pt-5">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                          sentMessReqestList?.map((item, index) => {
                            return (
                              <div
                                className="d-flex flex-stack py-2"
                                key={index}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-45px symbol-circle">
                                    <span className="symbol-label bg-light-success text-success fs-6 fw-bolder">
                                      {item.title}
                                    </span>
                                    <div
                                      className={
                                        "symbol-badge " +
                                        (item.is_active
                                          ? " bg-success "
                                          : " bg-danger ") +
                                        " start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
                                      }
                                    ></div>
                                  </div>

                                  <div className="ms-5">
                                    <a
                                      href="#"
                                      className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                                    >
                                      {item.name}
                                    </a>
                                  </div>
                                </div>

                                {/* <div className="d-flex flex-column align-items-end ms-2">
                                  <span className="text-muted fs-7 mb-1">
                                    {formatDateTime(item.request_time)}
                                  </span>
                                </div> */}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </>
                  </Tab>
                </Tabs>
              </div>
            </div>

            <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10 cls-dv-chat-right">
              {toUser ? (
                <div className="card" id="kt_chat_messenger">
                  <div className="card-header" id="kt_chat_messenger_header">
                    <div className="card-title">
                      <div className="d-flex justify-content-center flex-column me-3">
                        <a
                          href="#"
                          className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                        >
                          {toUser.name}{" "}
                        </a>
                        <span className="fs-7 fw-bold text-muted">
                          {toUser.is_active ? (
                            <span className="user-online">Online</span>
                          ) : (
                            <span>Offline</span>
                          )}
                        </span>
                        {/* <div className="mb-0 lh-1">
                        <span
                          className={
                            "badge " +
                            (toUser.is_active
                              ? " badge-success "
                              : " badge-danger ") +
                            " badge-circle w-10px h-10px me-1"
                          }
                        ></span>
                         <span className="fs-7 fw-bold text-muted">
                          {toUser.is_active ? "Online" : "Offline"}
                        </span>
                      </div>  */}
                      </div>
                    </div>
                  </div>

                  <div className="card-body" id="kt_chat_messenger_body">
                    <div
                      className="scroll-y me-n5 pe-5 h-300px h-lg-auto cls-chat-height"
                      data-kt-element="messages"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                      data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
                      data-kt-scroll-offset="5px"
                    >
                      {toUser.chat_messages.map((message, index) => {
                        if (message.FromUserID === userId) {
                          return (
                            <div
                              className="d-flex justify-content-end mb-10"
                              key={index}
                            >
                              <div className="d-flex flex-column align-items-end">
                                <div className="d-flex align-items-center mb-2">
                                  <div className="me-3">
                                    <a
                                      href="#"
                                      className="px-2 fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
                                    >
                                      You
                                    </a>
                                    <span className=" text-muted fs-7 mb-1">
                                      {formatDateTime(message.SentTime)}
                                    </span>
                                  </div>
                                  {/*
                                                                  <div className="symbol symbol-35px symbol-circle">
                                                                      <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder">
                                                                          {message.FromUserID?.charAt(0)}
                                                                      </span>
                                                                      <div
                                                                          className={
                                                                              "symbol-badge bg-primary start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
                                                                          }
                                                                      ></div>
                                                                  </div> */}
                                </div>

                                <div
                                  className="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end"
                                  data-kt-element="message-text"
                                >
                                  {message.Message}
                                </div>
                                <div>
                                  <img
                                    src={
                                      message.ReadStatus === "Y"
                                        ? blue_tick
                                        : grey_tick
                                    }
                                    width="15px"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="d-flex justify-content-start mb-10"
                              key={index}
                            >
                              <div className="d-flex flex-column align-items-start">
                                <div className="d-flex align-items-center mb-2">
                                  {/* <div className="symbol symbol-35px symbol-circle">
                                                                      <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                          {message.FromUserID?.charAt(0)}
                                                                      </span>
                                                                      <div className="symbol-badge bg-danger start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
                                                                  </div> */}

                                  <div className="ms-3">
                                    <a
                                      href="#"
                                      className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
                                    >
                                      {message.FromUser}
                                    </a>
                                    <span className="text-muted fs-7 mb-1">
                                      {formatDateTime(message.SentTime)}
                                    </span>
                                  </div>
                                </div>

                                <div
                                  className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start"
                                  data-kt-element="message-text"
                                >
                                  {message.Message}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}

                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                  <div
                    className="card-footer pt-4"
                    id="kt_chat_messenger_footer"
                  >
                    <div className="row">
                      <div className="col-10">
                        <input
                          className="form-control form-control-flush mb-3"
                          type="text"
                          name="textmessage"
                          placeholder="Type a message"
                          value={message}
                          onKeyDown={(e) => handleKeyDown(e)}
                          onKeyPress={(e) => sendTypingNotification(e)}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary"
                          type="button"
                          data-kt-element="send"
                          onClick={send}
                          ref={sendButtonRef}
                          disabled={!(message?.trim().length > 0)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                    {/* <div className="d-flex flex-stack">
                    <div className="d-flex align-items-center me-2"></div>

                    <button
                      className="btn btn-primary"
                      type="button"
                      data-kt-element="send"
                      onClick={send}
                      ref={sendButtonRef}
                      disabled={!(message?.trim().length > 0)}
                    >
                      Send
                    </button>
                  </div> */}
                  </div>
                </div>
              ) : requestMessageTo ? (
                <div
                  className="card"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      paddingTop: "100px",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    Message requested by{" "}
                    <span className="fw-bolder text-primary">
                      {requestMessageTo.name}
                    </span>
                  </p>
                  <div className="flex-container mb-5">
                    <div className="mx-5">
                      <a
                        className="btn btn-chat-accept-req"
                        onClick={() => acceptRequest(requestMessageTo)}
                      >
                        Accept
                      </a>
                    </div>
                    <div className="mx-5">
                      <a
                        className="btn btn-chat-reject-req"
                        onClick={() => rejectRequest(requestMessageTo)}
                      >
                        Reject
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="cls-chat-height justify-content-center align-items-center">
                  <p
                    style={{
                      color: "black",
                      fontSize: "20px",
                      paddingTop: "100px",
                      textAlign: "center",
                    }}
                  >
                    Your chat content / message request details goes here
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};