import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import "./project-details.css";
import { Loader } from "../components/loader";
import { useNavigate, Link } from 'react-router-dom';
import './dashboard.css'
import completed_img from '../images/complete.png'
import inprogress_img from '../images/arrows.png'
import created_img from '../images/add.png'
import draft_img from '../images/draft.png'
import others_img from '../images/more.png'
import submitted_img from '../images/submit.png'
import change_request_img from '../images/change.png'
import withdraw_img from '../images/withdrawal.png'
import dispute_img from '../images/dispute.png'
import reject_img from '../images/reject.png'
export const Dashboard: FC = () => {
	// const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [dashboard, setdashboard] = useState({
		"activeMilestones": [
			{
				"mileStoneId": "",
				"mileStoneTitle": "",
				"projectId": "",
				"projectTitle": ""
			}
		],
		"totalEarnings": {
			"inEscrow": 0,
			"pending": 0,
			"totalEarned": 0,
			"totalEstimated": 0
		},
		"totalMilestones": {
			"completed": 0,
			"inProgress": 0,
			"total": 0
		},
		"totalProjects": {
			"completed": 0,
			"inProgress": 0,
			"total": 0
		},
		"totalSpends": {
			"inEscrow": 0,
			"pending": 0,
			"totalEstimated": 0,
			"totalSpend": 0
		}
	})
	const { publicKey } = useWallet();
	const get_project = async () => {

		try {
			const response = await fetch(process.env.REACT_APP_API_URL + 'getDashboardData/' + publicKey.toString(), { mode: 'cors' });
			const data = await response.json();
			data['totalEarnings']['totalEstimated'] = parseFloat(data['totalEarnings']['totalEstimated']).toFixed(2);
			data['totalEarnings']['totalEarned'] = parseFloat(data['totalEarnings']['totalEarned']).toFixed(2);
			data['totalEarnings']['inEscrow'] = parseFloat(data['totalEarnings']['inEscrow']).toFixed(2);
			data['totalEarnings']['pending'] = parseFloat(data['totalEarnings']['pending']).toFixed(2);


			data['totalSpends']['totalEstimated'] = parseFloat(data['totalSpends']['totalEstimated']).toFixed(2);
			data['totalSpends']['totalSpend'] = parseFloat(data['totalSpends']['totalSpend']).toFixed(2);
			data['totalSpends']['inEscrow'] = parseFloat(data['totalSpends']['inEscrow']).toFixed(2);
			data['totalSpends']['pending'] = parseFloat(data['totalSpends']['pending']).toFixed(2);


			console.log(data)
			data['recentProjects'] = data['recentProjects'].slice(0, 6)
			setdashboard(data)
			setIsLoading(false)
		}
		catch (e) {
			console.log(e)
		}
	}

	let call_project_details = param => e => {
		console.log(param)
		localStorage.setItem('project_id', param)
		// window.location.href = '/project-milestone/'+param;
		navigate('/project-milestone/' + param);
	}

	useEffect(() => {
		setIsLoading(true)
		if (publicKey) {
			get_project();
			//   setInterval(init, 1000);
		}
	}, [publicKey]);




	return (<>
		{isLoading && <Loader />}
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<div className="container-xxl" id="kt_content_container">
				<div className="row g-5 g-xl-8">
					<div className="col-xl-4">

						<div className="card card-xl-stretch mb-xl-8">

							<div className="card-body p-0">

								<div className="px-9 pt-7 card-rounded h-175px w-100 total-project-card">

									<div className="d-flex text-center flex-column text-white pt-8">
										<span className="fw-bold fs-1">Total Projects</span>
										<span className="fw-bolder fs-2x pt-1">{dashboard['totalProjects']['total']}</span>
									</div>

								</div>


								<div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1">

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={draft_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">In Draft</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalProjects']['inDraft']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={created_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Created</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalProjects']['created']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={inprogress_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">In Progress</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalProjects']['inProgress']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={completed_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Completed</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalProjects']['completed']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={others_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Others</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalProjects']['others']}</div>
											</div>

										</div>

									</div>
								</div>

							</div>

						</div>

					</div>
					<div className="col-xl-4">

						<div className="card card-xl-stretch mb-xl-8">

							<div className="card-body p-0">

								<div className="px-9 pt-7 card-rounded h-175px w-100 total-project-card">

									<div className="d-flex text-center flex-column text-white pt-8">
										<span className="fw-bold fs-1">Total Milestone</span>
										<span className="fw-bolder fs-2x pt-1">{dashboard['totalMilestones']['total']}</span>
									</div>

								</div>


								<div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1">

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={created_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Created</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalMilestones']['created']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={inprogress_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">In Progress</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalMilestones']['inProgress']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={completed_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Completed</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalMilestones']['completed']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={dispute_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">In Dispute</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalMilestones']['inDispute']}</div>
											</div>

										</div>

									</div>

									<div className="d-flex align-items-center mb-6">

										<div className="symbol symbol-45px w-40px me-5">
											<img src={others_img} />
										</div>


										<div className="d-flex align-items-center flex-wrap w-100">

											<div className="mb-1 pe-3 flex-grow-1">
												<a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Others</a>

											</div>


											<div className="d-flex align-items-center">
												<div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboard['totalMilestones']['others']}</div>
											</div>

										</div>

									</div>
								</div>

							</div>

						</div>

					</div>
					<div className="col-xl-4">
						<div className="card card-flush mb-5">

							<div className="card-header pt-5">

								<div className="card-title d-flex flex-column">
									<span className="pt-1 fw-bold fs-6">Estimated Earning</span>
									<div className="d-flex align-items-center">


										<span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{dashboard['totalEarnings']['totalEstimated']}</span>

										<span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">◎</span>

									</div>




								</div>

							</div>


							<div className="card-body pt-5">

								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">Total Earned</div>


									<div className="d-flex align-items-senter">

										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalEarnings']['totalEarned']}◎</span>


									</div>

								</div>


								<div className="separator separator-dashed my-3"></div>


								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">In Escrow</div>


									<div className="d-flex align-items-senter">

										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalEarnings']['inEscrow']}◎</span>

									</div>

								</div>


								<div className="separator separator-dashed my-3"></div>


								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">Pending</div>
									<div className="d-flex align-items-senter">
										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalEarnings']['pending']}◎</span>

									</div>

								</div>

							</div>

						</div>
						<div className="card card-flush">

							<div className="card-header pt-5">

								<div className="card-title d-flex flex-column">
									<span className="pt-1 fw-bold fs-6">Estimated Spending</span>
									<div className="d-flex align-items-center">


										<span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{dashboard['totalSpends']['totalEstimated']}</span>
										<span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">◎</span>



									</div>




								</div>

							</div>


							<div className="card-body pt-5">

								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">Total Spending</div>


									<div className="d-flex align-items-senter">

										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalSpends']['totalSpend']}◎</span>


									</div>

								</div>


								<div className="separator separator-dashed my-3"></div>


								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">In Escrow</div>


									<div className="d-flex align-items-senter">

										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalSpends']['inEscrow']}◎</span>

									</div>

								</div>


								<div className="separator separator-dashed my-3"></div>


								<div className="d-flex flex-stack">

									<div className="text-gray-700 fw-bold fs-6 me-2">Pending</div>
									<div className="d-flex align-items-senter">
										<span className="text-gray-900 fw-boldest fs-6">{dashboard['totalSpends']['pending']}◎</span>

									</div>

								</div>

							</div>

						</div>
					</div>
				</div>

				<div className="row g-5 g-xl-10 mb-xl-10">
					<div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
						<div className="card card-xxl-stretch mb-5 mb-xl-8">

							<div className="card-header border-0 pt-5">
								<h3 className="card-title align-items-start flex-column">
									<span className="card-label fw-bolder fs-3 mb-1">Project Details</span>

								</h3>
								<div className="card-toolbar" >

									<Link className="btn btn-sm btn-view-all" to={{ pathname: '/my-project' }} state={{ from: "my-project" }}>
										View All Projects
									</Link>
								</div>
							</div>


							<div className="card-body py-3">

								<div className="table-responsive">

									<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
										<thead>
											<tr className="fw-bolder text-muted">

												<th className="min-w-150px">Project Name </th>
												<th className="min-w-150px">Status</th>
												<th className="min-w-100px text-end">Actions</th>
											</tr>
										</thead>
										{(dashboard && 'recentProjects' in dashboard && dashboard['recentProjects'].length > 0) ? (
											<tbody>
												{dashboard['recentProjects'].map((recentProjects, index) => (
													<tr>

														<td>
															<a href="#" onClick={call_project_details(recentProjects.projectId)} className="text-dark fw-bolder text-hover-primary d-block fs-6">{recentProjects.projectTitle}</a>
														</td>
														<td className="text-end">
															<div className="d-flex flex-column w-100 me-2">
																<div className="d-flex flex-stack mb-2">
																	<span className="me-2 fs-7 fw-bold">{recentProjects.status}</span>
																</div>
																{/* <div className="progress h-6px w-100">
										<div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }}></div>
									</div> */}
															</div>
														</td>
														<td>
															<div className="d-flex justify-content-end flex-shrink-0">
																<a href="#" onClick={call_project_details(recentProjects.projectId)} className="btn btn-view-project btn-sm px-4 me-2">View</a>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										) : ''}
									</table>

								</div>

							</div>

						</div>
					</div>
					<div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">

						<div className="card h-xl-100">

							<div className="card-header border-0 pt-5">
								<h3 className="card-title align-items-start flex-column">
									<span className="card-label fw-bolder text-dark">Active Milestones</span>
									<span className="text-muted mt-1 fw-bold fs-7"></span>
								</h3>



							</div>
							{(dashboard && dashboard['activeMilestones'].length > 0) ? (
								<div className="card-body pt-6">
									{dashboard['activeMilestones'].map(milestone => (
										<div>
											<div className="d-flex flex-stack">

												<div className="symbol symbol-40px me-4">
													{milestone.mileStoneTitle != null && <div className="symbol-label fs-2 fw-bold bg-info text-inverse-danger">{milestone.mileStoneTitle[0]}</div>}
												</div>


												<div className="d-flex align-items-center flex-row-fluid flex-wrap">

													<div className="flex-grow-1 me-2">
														<a href="#" onClick={call_project_details(milestone.projectId)} className="text-gray-800 text-hover-primary fs-6 fw-bolder">{milestone.mileStoneTitle}</a>
														<span className="text-muted fw-bold d-block fs-7">{milestone.projectTitle}</span>
													</div>


													<a href="#" onClick={call_project_details(milestone.projectId)} className="btn btn-sm btn-icon btn-active-milestone w-30px h-30px">

														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
																<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
															</svg>
														</span>

													</a>

												</div>

											</div>


											<div className="separator separator-dashed my-4"></div>
										</div>

									))}
								</div>
							) : <div className="card-body pt-6"><div className="d-flex flex-stack text-center"><h5 className="text-primary">No Active Milestones</h5></div></div>}



						</div>
					</div>

				</div>


				<div className="row g-5 g-xl-8">



					<div className="col-xxl-12">



					</div>

				</div>

			</div>
		</div>
	</>);
};