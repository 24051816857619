import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
    createEscrowInitTransaction,
    createEscrowValidateTransaction,
    createEscrowApproveTransaction,
    createInitiatorWithdrawTransaction,
    createSPLEscrowInitTransaction,
    createSPLEscrowValidateTransaction,
    createSPLEscrowApproveTransaction,
    createSPLInitiatorWithdrawTransaction,
    printEscrowData
} from "../util/escrow";

import "./project-details.css";
import { exit } from "process";
import { Link } from "react-router-dom";
import "./project-milestone.css"
import S3 from "react-aws-s3";
import moment from "moment";

export const ProjectMilestone = ({ socket }) => {
    let navigate = useNavigate()
    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    const [isLoading, setIsLoading] = useState(false);
    const [reloadRoute, setreloadRoute] = useState(false);
    const { connection } = useConnection();
    const { wallet, publicKey, sendTransaction } = useWallet();
    const [contract, setcontract] = useState({})
    const [current_user_type, setcurrent_user_type] = useState('');
    const [show_accept_contract_btn, setshow_accept_contract_btn] = useState(false);
    const [escrowVaultWord, setescrowVaultWord] = useState("");
    const [walletAddress, setwalletAddress] = useState("");
    const [submittext, setsubmittext] = useState("");
    const [requestChangeDetails, setrequestChangeDetails] = useState("");
    const [viewTransaction, setviewTransaction] = useState(false);
    const [currentTransaction, setcurrentTransaction] = useState([]);
    const [viewRevisionHistory, setviewRevisionHistory] = useState(false);
    const [currentRevisionHistory, setcurrentRevisionHistory] = useState([]);
    const [requestChange, setrequestChange] = useState(false);
    const [submitWorkModal, setsubmitWorkModal] = useState(false);
    const [submitWorkModalTitle, setsubmitWorkModalTitle] = useState('');
    const fileInput: any = useRef();
    const [uploadSucess, setUploadSucess] = useState(false);
    const [fileNameFrReq, setFileNameFrReq] = useState();
    const [file, setFile] = useState();

    let { project_id } = useParams();


    // useEffect(() => {
    const sendAndConfirmTransaction = useCallback(
        async (transaction) => {
            try {
                let { blockhash } = await connection.getRecentBlockhash();
                transaction.feePayer = publicKey!;
                transaction.recentBlockhash = blockhash;

                let signature = await sendTransaction(transaction, connection);
                await connection.confirmTransaction(signature, "confirmed");

                console.log(signature);

                // if (onChange) onChange();

                return signature;
            } catch (err) {
                setIsLoading(false)
                console.log("Transaction error: ", err);
                Swal.fire({
                    title: "Error in transaction",
                    text: err.message,
                    confirmButtonText:
                        'Close'
                })

                setIsLoading(false)
                setreloadRoute(true)
                console.log("Transaction error: ", err);
            }
        },
        [connection, publicKey, sendTransaction]
    );

    const EscrowValidate = useCallback(
        async () => {
            if (!publicKey) return;

            console.log("walletAddress " + localStorage.getItem('walletAddress'))
            console.log('escrowVaultWord ' + localStorage.getItem('escrowVaultWord'))

            let sig = await sendAndConfirmTransaction(
                await createEscrowValidateTransaction(connection, publicKey, new PublicKey(localStorage.getItem('walletAddress')), localStorage.getItem('escrowVaultWord'))
            );
            if (sig) {

                let data = {
                    "milestoneId": localStorage.getItem('m_id'),
                    "projectId": localStorage.getItem('p_id'),
                    "signature": sig,
                    "walletAddress": publicKey.toString()
                }


                try {

                    let res = await fetch(process.env.REACT_APP_API_URL + "acceptContract", {
                        method: "POST",
                        mode: 'cors',
                        headers: new Headers({ 'content-type': 'application/json' }),
                        body: JSON.stringify(data),
                    });
                    let resJson = await res.json();
                    console.log(resJson)
                    if (res.status === 200) {                        
                        if (publicKey.toString() == localStorage.getItem('n_s_a')) {
                            notify_user("Seller accepted the contract", localStorage.getItem('n_b_a'));
                        } else {
                            notify_user("Buyer accepted the contract", localStorage.getItem('n_s_a'));
                        }
                        Swal.fire({
                            title: 'Milestone accepted.',
                            confirmButtonText:
                                'Close'
                        })


                        console.log("success")
                        setreloadRoute(true)
                        setIsLoading(false)
                    } else {
                        console.log("failure")
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [connection, publicKey, walletAddress, escrowVaultWord, sendAndConfirmTransaction]
    );


    async function approve_contract(milestone_id) {
        setIsLoading(true)
        const balance = await connection.getBalance(publicKey);
        if (((balance / LAMPORTS_PER_SOL)) < 0.000005) {
            console.log(balance)
            let remaining_amt;
            if (balance / LAMPORTS_PER_SOL == 0) {
                remaining_amt = 0.000005;
            } else {
                remaining_amt = (0.000005 - (balance / LAMPORTS_PER_SOL)).toFixed();
            }

            setIsLoading(false)
            Swal.fire({
                title: "You have insufficient funds in your wallet",
                text: "You need " + remaining_amt + " SOL to accept this contract",
                confirmButtonText:
                    'Close'
            })
        } else {
            localStorage.setItem('p_id', contract['projectId'])
            localStorage.setItem('m_id', milestone_id)
            localStorage.setItem('last_api_call', 'approve_contract')

            try {
                let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + contract['projectId'] + "/" + milestone_id, {
                    mode: 'cors',
                });
                let resJson = await access_res.text();
                console.log(resJson)
                localStorage.setItem('escrowVaultWord', resJson)
                setescrowVaultWord(resJson)
                localStorage.setItem('walletAddress', contract['buyerAddress'])
                setwalletAddress(contract['buyerAddress'])
                await EscrowValidate();
            }
            catch (e) {
                console.log(e)
            }
        }

    }




    const get_project = async () => {

        console.log("get project")
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'getProject/' + localStorage.getItem('project_id') + '/' + localStorage.getItem('p_key'), { mode: 'cors' });
            const data = await response.json();
            if (data.createdBy == 'buyer') {
                if (data.buyerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('buyer')
                } else if (data.sellerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('seller')
                }
            } else {
                if (data.sellerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('buyer')
                } else if (data.buyerAddress == localStorage.getItem('p_key')) {
                    setcurrent_user_type('seller')
                }
            }
            localStorage.setItem('n_b_a', data['buyerAddress'])
            localStorage.setItem('n_s_a', data['sellerAddress'])
            setcontract(data)
            setIsLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    }

    async function reject_contract(milestone_id) {
        setIsLoading(true)
        localStorage.setItem('last_api_call', 'reject_contract')

        let data = {
            "milestoneId": milestone_id,
            "projectId": contract['projectId'],
            "walletAddress": publicKey.toString()
        }

        try {

            let res = await fetch(process.env.REACT_APP_API_URL + "rejectContract", {
                method: "POST",
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(data),
            });
            let resJson = await res.json();
            console.log(resJson)
            setreloadRoute(true)
            setIsLoading(false)
            if (res.status === 200) {
                if (publicKey.toString() == localStorage.getItem('n_s_a')) {
                    notify_user("Seller rejected the contract", localStorage.getItem('n_b_a'));
                } else {
                    notify_user("Buyer rejected the contract", localStorage.getItem('n_s_a'));
                }

                Swal.fire({
                    title: 'Contract rejected. ',
                    confirmButtonText:
                        'Close'
                })
                console.log("success")

            } else {
                console.log("failure")
            }
        } catch (err) {
            console.log(err);
        }

    }

    async function submitWork(milestone_id) {
        localStorage.setItem('last_api_call', 'submit_work')
        if (submittext.length == 0) {
            Swal.fire({
                title: 'Work details is empty',
                text: 'Please add and submit your work',
                confirmButtonText:
                    'Close'
            })
        } else {
            setIsLoading(true)
            let data = {
                "attachments": [
                    "string"
                ],
                "description": submittext,
                "milestoneId": milestone_id,
                "projectId": contract['projectId'],
                "walletAddress": publicKey.toString()
            }

            try {

                let res = await fetch(process.env.REACT_APP_API_URL + "submitWork", {
                    method: "POST",
                    mode: 'cors',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify(data),
                });
                let resJson = await res.json();
                console.log(resJson)
                setsubmitWorkModal(false)
                if (res.status === 200) {

                    notify_user("Seller completed the work and submitted for review", contract['buyerAddress']);
                    setreloadRoute(true)
                    setIsLoading(false)
                    get_project()
                    Swal.fire({
                        title: 'Your work has been submitted successfully. ',
                        confirmButtonText:
                            'Close'
                    })
                    console.log("success")

                } else {
                    console.log("failure")
                }
            } catch (err) {
                console.log(err);
            }

        }

    }


    async function submit_work_modal(milestone_id, triggeredFrom?) {
        if(triggeredFrom == 'Submit Work'){
            setsubmitWorkModalTitle("Submit Work")
        }else{
            setsubmitWorkModalTitle("Submit Revision")
        }
        setsubmitWorkModal(true)
        localStorage.setItem('s_m_id', milestone_id)
        localStorage.setItem('s_triggered_from', triggeredFrom)
    }

    const handle_close_submit_work_modal = () => setsubmitWorkModal(false);


    let submit_work = async (e) => {
        e.preventDefault();
        let milestone_id = localStorage.getItem('s_m_id')
        if (milestone_id && fileInput &&  localStorage.getItem('s_triggered_from')) {
            let file = fileInput!.current.files[0];
            if (file) {
                handleClick(milestone_id, localStorage.getItem('s_triggered_from'));
            } else {
                submitWork(milestone_id);
            }
        } else {
            submitWork(milestone_id);
        }

    }


    async function request_change_modal(milestone_id) {
        setrequestChange(true)
        localStorage.setItem('r_m_id', milestone_id)
    }

    const handle_close_request_change_modal = () => setrequestChange(false);


    let submit_request_change = async (e) => {
        e.preventDefault();
        if (requestChangeDetails.length == 0) {
            Swal.fire({
                title: 'Request details is empty',
                text: 'Please add and submit your request',
                confirmButtonText:
                    'Close'
            })
        } else {
            setIsLoading(true)
            localStorage.setItem('last_api_call', 'request_change')
            let data = {
                "attachments": [
                    null
                ],
                "description": requestChangeDetails,
                "milestoneId": localStorage.getItem('r_m_id'),
                "projectId": contract['projectId'],
                "walletAddress": publicKey.toString()
            }

            try {

                let res = await fetch(process.env.REACT_APP_API_URL + "requestChange", {
                    method: "POST",
                    mode: 'cors',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify(data),
                });

                setrequestChange(false)
                if (res.status === 200) {

                    notify_user("Buyer raise the change request on work you submitted", contract['sellerAddress']);

                    Swal.fire({
                        title: 'Your change request has been submitted successfully . ',
                        confirmButtonText:
                            'Close'
                    })
                    setreloadRoute(true)
                    setIsLoading(false)
                    console.log("success")

                } else {
                    console.log("failure")
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    async function request_change(milestone_id) {

        setIsLoading(true)
        localStorage.setItem('last_api_call', 'request_change')
        let data = {
            "attachments": [
                "string"
            ],
            "description": "string",
            "milestoneId": milestone_id,
            "projectId": contract['projectId'],
            "walletAddress": publicKey.toString()
        }

        try {

            let res = await fetch(process.env.REACT_APP_API_URL + "requestChange", {
                method: "POST",
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(data),
            });
            // let resJson = await res.json();
            // console.log(resJson)
            setreloadRoute(true)
            setIsLoading(false)
            if (res.status === 200) {

                notify_user("Buyer raise the change request on work you submitted", contract['sellerAddress']);

                Swal.fire({
                    title: 'Your change request has been submitted successfully . ',
                    confirmButtonText:
                        'Close'
                })
                console.log("success")

            } else {
                console.log("failure")
            }
        } catch (err) {
            console.log(err);
        }

    }

    async function release_payment(milestone_id) {

        Swal.fire({
            title: 'Tamper-proof confirmation',
            text: 'You can not reverse this transaction',
            showDenyButton: true,
            confirmButtonText: 'Confirm',
            denyButtonText: 'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                release_payment_confirmed(milestone_id)
            } else if (result.isDenied) {

            }
        })


    }

    async function retry_approve_contract(milestine_id) {
        setIsLoading(true)
        let faild_milestone = contract['mileStones'].find(o => o.mileStoneId === milestine_id);
        let signature = faild_milestone['allTransactionSignatures'].find(o => o.signatureType === "Accepted");
        let data = {
            "milestoneId": milestine_id,
            "projectId": contract['projectId'],
            "signature": signature.signatureHash,
            "walletAddress": publicKey.toString()
        }


        try {

            let res = await fetch(process.env.REACT_APP_API_URL + "acceptContract", {
                method: "POST",
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(data),
            });
            let resJson = await res.json();
            console.log(resJson)
            if (res.status === 200) {
                Swal.fire({
                    title: 'Milestone accepted.',
                    confirmButtonText:
                        'Close'
                })
                console.log("success")
                setreloadRoute(true)
                setIsLoading(false)
            } else {
                console.log("failure")
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function retry_release_payment(milestine_id) {

        setIsLoading(true)
        let faild_milestone = contract['mileStones'].find(o => o.mileStoneId === milestine_id);
        let signature = faild_milestone['allTransactionSignatures'].find(o => o.signatureType === "Completed");
        let data = {
            "milestoneId": milestine_id,
            "projectId": contract['projectId'],
            "signature": signature.signatureHash,
            "walletAddress": publicKey.toString()
        }


        try {

            let res = await fetch(process.env.REACT_APP_API_URL + "releasePayment", {
                method: "POST",
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(data),
            });
            let resJson = await res.json();
            console.log(resJson)
            if (res.status === 200) {
                setreloadRoute(true)
                setIsLoading(false)
                Swal.fire({
                    title: 'Payment released',
                    confirmButtonText:
                        'Close'
                })
                console.log("success")

            } else {
                console.log("failure")
            }
        } catch (err) {
            console.log(err);
        }
    }


    async function retry_api_call(milestone_id) {
        let last_api_call = localStorage.getItem('last_api_call')

        if (last_api_call == 'approve_contract') {
            retry_approve_contract(milestone_id)
        } else if (last_api_call == 'reject_contract') {
            reject_contract(milestone_id)
        } else if (last_api_call == 'submit_work') {
            submit_work(milestone_id)
        } else if (last_api_call == 'request_change') {
            request_change(milestone_id)
        } else if (last_api_call == 'release_payment') {
            retry_release_payment(milestone_id)
        } else if (last_api_call == 'activate_contract') {
            // activate_contract(milestone_id)
        } else if (last_api_call == 'withdraw') {
            withdraw_amount(milestone_id)
        }
    }
    async function release_payment_confirmed(milestone_id) {
        localStorage.setItem('last_api_call', 'release_payment')
        setIsLoading(true)
        localStorage.setItem('p_id', contract['projectId'])
        localStorage.setItem('m_id', milestone_id)

        try {
            let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + contract['projectId'] + "/" + milestone_id, {
                mode: 'cors',
            });
            let resJson = await access_res.text();
            localStorage.setItem('escrowVaultWord', resJson)
            localStorage.setItem('walletAddress', contract['sellerAddress'])
            setwalletAddress(contract['buyerAddress'])
            await EscrowApprove();


        }
        catch (e) {
            console.log(e)
        }
    }

    const EscrowApprove = useCallback(

        async () => {
            if (!publicKey) return;
            console.log("walletAddress " + localStorage.getItem('walletAddress'))
            console.log('escrowVaultWord ' + localStorage.getItem('escrowVaultWord'))

            let sig = await sendAndConfirmTransaction(
                await createEscrowApproveTransaction(connection, publicKey, new PublicKey(localStorage.getItem('walletAddress')), localStorage.getItem('escrowVaultWord'))
            );
            if (sig) {

                let data = {
                    "milestoneId": localStorage.getItem('m_id'),
                    "projectId": localStorage.getItem('p_id'),
                    "signature": sig,
                    "walletAddress": publicKey.toString()
                }


                try {

                    let res = await fetch(process.env.REACT_APP_API_URL + "releasePayment", {
                        method: "POST",
                        mode: 'cors',
                        headers: new Headers({ 'content-type': 'application/json' }),
                        body: JSON.stringify(data),
                    });
                    let resJson = await res.json();
                    console.log(resJson)
                    if (res.status === 200) {

                        notify_user("Buyer released the payment for the contract", contract['sellerAddress']);

                        setreloadRoute(true)
                        setIsLoading(false)
                        Swal.fire({
                            title: 'Payment released',
                            confirmButtonText:
                                'Close'
                        })
                        console.log("success")

                    } else {
                        console.log("failure")
                    }
                } catch (err) {
                    console.log(err);
                }
            }

        },
        [connection, publicKey, sendAndConfirmTransaction]
    );


    async function activate_contract(milestone_id, amount) {

        const balance = await connection.getBalance(publicKey);
        let t = (Number(amount) + 0.000005 + 0.00151728);
        console.log(balance)
        if (((balance / LAMPORTS_PER_SOL)) < t) {
            let remaining_amt = t - (balance / LAMPORTS_PER_SOL);
            Swal.fire({
                title: "You have insufficient funds in your wallet",
                text: "You need " + remaining_amt.toFixed(5) + " SOL more to create this contract",
                confirmButtonText:
                    'Close'
            })
        } else {

            localStorage.setItem('last_api_call', 'activate_contract')
            localStorage.setItem('p_id', contract['projectId'])
            localStorage.setItem('m_id', milestone_id)

            try {
                let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + contract['projectId'] + "/" + milestone_id, {
                    mode: 'cors',
                });
                let resJson = await access_res.text();
                localStorage.setItem('escrowVaultWord', resJson)
                localStorage.getItem('transactionSOL')
                localStorage.setItem('validator', contract['sellerAddress'])

                localStorage.setItem('transactionSOL', amount)
                setwalletAddress(contract['buyerAddress'])

                await EscrowInit();
            }
            catch (e) {
                console.log(e)
            }
        }
    }

    const EscrowInit = useCallback(
        async () => {
            if (!publicKey) return;


            let deposit = Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL;
            console.log("walletAddress " + localStorage.getItem('validator'))
            console.log('escrowVaultWord ' + localStorage.getItem('escrowVaultWord'))
            console.log('transactionSOL ' + localStorage.getItem('transactionSOL'))
            console.log('transactionSOL ' + Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL)
            console.log('deposit ' + deposit)
            let sig = await sendAndConfirmTransaction(
                await createEscrowInitTransaction(connection, publicKey, new PublicKey(localStorage.getItem('validator')), localStorage.getItem('escrowVaultWord'), Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL, deposit)
            );
            console.log(sig)
            if (sig) {

                let data = {
                    "milestoneId": localStorage.getItem('m_id'),
                    "projectId": localStorage.getItem('p_id'),
                    "signature": sig,
                    "walletAddress": publicKey.toString()
                }

                // Update the contract API
                try {

                    let res = await fetch(process.env.REACT_APP_API_URL + "initiateContract", {
                        method: "POST",
                        mode: 'cors',
                        headers: new Headers({ 'content-type': 'application/json' }),
                        body: JSON.stringify(data),
                    });
                    let resJson = await res.json();
                    console.log(resJson)

                    if (res.status === 200) {
                        setreloadRoute(true)
                        setIsLoading(false)
                        get_project()
                        console.log("Notification " + localStorage.getItem('n_s_a'))
                        notify_user("Buyer activated the milestone", localStorage.getItem('n_s_a'));
                        Swal.fire({
                            title: 'Your milestone has successfully activated. ',
                            confirmButtonText:
                                'Close'
                        })
                        console.log("success")

                    } else {
                        console.log("failure")
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [connection, publicKey, walletAddress, project_id, sendAndConfirmTransaction]
    );


    async function withdraw_amount(milestone_id) {
        setIsLoading(true)
        localStorage.setItem('last_api_call', 'withdraw')
        localStorage.setItem('p_id', contract['projectId'])
        localStorage.setItem('m_id', milestone_id)

        try {
            let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + contract['projectId'] + "/" + milestone_id, {
                mode: 'cors',
            });
            let resJson = await access_res.text();
            localStorage.setItem('escrowVaultWord', resJson)
            localStorage.setItem('walletAddress', contract['sellerAddress'])
            setwalletAddress(contract['buyerAddress'])
            await InitiatorWithdraw();
        }
        catch (e) {
            console.log(e)
        }
    }

    const InitiatorWithdraw = useCallback(
        async () => {
            if (!publicKey) return;
            let sig = await sendAndConfirmTransaction(
                await createInitiatorWithdrawTransaction(connection, publicKey, new PublicKey(localStorage.getItem('walletAddress')), localStorage.getItem('escrowVaultWord'))
            );
            console.log(sig)
            if (sig) {
                let data = {
                    "milestoneId": localStorage.getItem('m_id'),
                    "projectId": localStorage.getItem('p_id'),
                    "signature": sig,
                    "walletAddress": publicKey.toString()
                }


                try {

                    let res = await fetch(process.env.REACT_APP_API_URL + "withdrawContract", {
                        method: "POST",
                        mode: 'cors',
                        headers: new Headers({ 'content-type': 'application/json' }),
                        body: JSON.stringify(data),
                    });
                    let resJson = await res.json();
                    setreloadRoute(true)
                    setIsLoading(false)
                    notify_user("Buyer withdrawn the milestone", localStorage.getItem('n_s_a'));
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'You have successfully withdrawn your payment. ',
                            confirmButtonText:
                                'Close'
                        })
                        console.log("success")

                    } else {
                        console.log("failure")
                    }
                } catch (err) {
                    console.log(err);
                }

            }

        },
        [connection, publicKey, sendAndConfirmTransaction]
    );


    async function initiate_contract(milestone_id, amount) {
        localStorage.setItem('last_api_call', 'release_payment')
        setIsLoading(true)
        localStorage.setItem('p_id', contract['projectId'])
        localStorage.setItem('m_id', milestone_id)
        localStorage.setItem('validator', contract['sellerAddress'])
        localStorage.setItem('transactionSOL', amount)


        try {
            let access_res = await fetch(process.env.REACT_APP_API_URL + "getAccessKey/" + contract['projectId'] + "/" + milestone_id, {
                mode: 'cors',
            });
            let resJson = await access_res.text();
            localStorage.setItem('escrowVaultWord', resJson)
            await EscrowInitByBuyer();


        }
        catch (e) {
            console.log(e)
        }
    }

    // If Buyer is creating deposit = amount
    // If Seller is creating deposit = 0
    const EscrowInitByBuyer = useCallback(
        async () => {
            if (!publicKey) return;


            let deposit = Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL;
            console.log("walletAddress " + localStorage.getItem('validator'))
            console.log('escrowVaultWord ' + localStorage.getItem('escrowVaultWord'))
            console.log('transactionSOL ' + localStorage.getItem('transactionSOL'))
            console.log('transactionSOL ' + Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL)
            console.log('deposit ' + deposit)
            let sig = await sendAndConfirmTransaction(
                await createEscrowInitTransaction(connection, publicKey, new PublicKey(localStorage.getItem('validator')), localStorage.getItem('escrowVaultWord'), Number(localStorage.getItem('transactionSOL')) * LAMPORTS_PER_SOL, deposit)
            );
            console.log(sig)

            if (sig) {
                let data = {
                    "milestoneId": localStorage.getItem('m_id'),
                    "projectId": localStorage.getItem('p_id'),
                    "signature": sig,
                    "walletAddress": publicKey.toString()
                }

                // Update the contract API
                try {

                    let res = await fetch(process.env.REACT_APP_API_URL + "initiateContract", {
                        method: "POST",
                        mode: 'cors',
                        headers: new Headers({ 'content-type': 'application/json' }),
                        body: JSON.stringify(data),
                    });
                    let resJson = await res.json();
                    console.log(resJson)

                    if (res.status === 200) {
                        setIsLoading(false)
                        notify_user("Buyer activated the contract", localStorage.getItem('n_s_a'));
                        Swal.fire({
                            title: 'Your transaction was successful. ',
                            confirmButtonText:
                                'Close'
                        })
                        setreloadRoute(true)
                        console.log("success")

                    } else {
                        console.log("failure")
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [connection, publicKey, walletAddress, sendAndConfirmTransaction]
    );

    useEffect(() => {
        get_project();
    }, [reloadRoute]);

    useEffect(() => {
        get_project();
    }, [navigate]);


    useEffect(() => {
        setIsLoading(true)
        // if (publicKey) {
        get_project();
        // exit;
        //   setInterval(init, 1000);
        // }
    }, []);

    const handleClose = () => setviewTransaction(false);

    function view_transactions(all_transactions) {
        console.log(all_transactions)
        setcurrentTransaction(all_transactions)
        setviewTransaction(true)
    }

    function view_revision_history(all_revision_history) {
        setcurrentRevisionHistory(all_revision_history)
        setviewRevisionHistory(true)
    }

    let notify_user = async (msg, to_address) => {
        console.log(msg, to_address)
        let res = await fetch(process.env.REACT_APP_API_URL + "saveMessage", {
            method: "POST",
            mode: 'cors',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "fromAddress": publicKey.toString(),
                "message": msg,
                "milestoneId": localStorage.getItem('m_id'),
                "projectId": localStorage.getItem('p_id'),
                "readStatus": false,
                "toAddress": to_address

            }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
            socket?.emit("sendText", {
                message: msg,
                from: publicKey.toString(),
                to: to_address
            });
        } else {
            console.log("failure")
        }



    }
    const saveAttachment = async (newFileName, triggeredFrom, milestoneId) => {
        let data = {
            "attachment": newFileName,
            "milestoneid": milestoneId,
            "projectId": localStorage.getItem('project_id'),
            "walletAddress": localStorage.getItem('p_key'),
            "attchmentState": triggeredFrom
        }
        try {
            setIsLoading(true);
            let res = await fetch('http://35.236.13.232:3000/api/v1/saveAttachment', {
                method: "POST",
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(data),
            });
            let resJson = await res.json();
            console.log(resJson)
            if (res.status === 200) {
                console.log("success")
                setIsLoading(false);

                Swal.fire({
                    title: 'File is uploaded Successfully.',
                    text: '',
                    confirmButtonText:
                        'Ok'
                }).then(
                    () => {
                        submitWork(milestoneId);
                    }
                )
            } else {
                console.log("failure")
            }

        } catch (e) {
            setIsLoading(false);
        }
    }

    const handleClick = (milestoneId, triggeredFrom) => {
        // event.preventDefault();
        setUploadSucess(false);
        if (fileInput) {
            let file = fileInput!.current.files[0];
            let projectId = contract['projectId'];
            if (file) {
                let newFileName = fileInput!.current.files[0].name.replace(/\..+$/, "");
                newFileName = projectId.substring(0, projectId.indexOf("-")) + "_" + milestoneId.substring(0, milestoneId.indexOf("-")) + "_" + Math.random() + file.name;
                setFileNameFrReq(newFileName);
                const config = {
                    bucketName: process.env.REACT_APP_BUCKET_NAME,
                    //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
                    region: process.env.REACT_APP_REGION,
                    accessKeyId: process.env.REACT_APP_ACCESS_ID,
                    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
                    s3Url: process.env.REACT_APP_S3URL
                };
                const ReactS3Client = new S3(config);
                ReactS3Client.uploadFile(file, newFileName).then((data) => {
                    console.log(data);
                    if (data.status === 204) {
                        console.log("success");
                        fileInput.current.value = "";
                        setUploadSucess(true);
                        saveAttachment(newFileName, triggeredFrom, milestoneId);
                    } else {
                        console.log("fail");
                        fileInput.current.value = "";
                        setUploadSucess(false);
                    }
                });

            }

        } else {
            console.log("fail");
            setUploadSucess(false);
        }

    };
    const handleChange = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0].name);
        }

    }

    const gettime = (timestamp) => {
        var stillUtc = moment.utc(timestamp, 'DD/MM/YYYY').toDate();
        var local = moment(stillUtc, 'DD/MM/YYYY').local().format('MMMM Do YYYY')

        return local;
    }


    return (<>
        {isLoading && <Loader />}

        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {contract &&
                <div className="container-xxl" id="kt_content_container">
                    <div className="card mb-5 mb-xl-10">

                        <div className="card-body pt-9 pb-0">

                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="me-7 mb-4 project-firstname-container">
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        <div className="project-firstname">{'projectTitle' in contract ? contract['projectTitle'][0] : ''}</div>

                                    </div>
                                </div>


                                <div className="flex-grow-1">

                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                        <div className="d-flex flex-column">

                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#" className="text-gray-900 text-hover-primary fs-2 me-1">{contract['projectTitle']}</a>

                                            </div>


                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                <a href="#" className="d-flex align-items-center  text-hover-primary mb-2">

                                                    {current_user_type == 'seller' ? "Buyer" : "Seller"}
                                                </a>:

                                                <a href="#" className="mx-2 d-flex align-items-center  text-hover-primary mb-2" data-toggle="tooltip" title={current_user_type == 'seller' ? contract['buyerAddress'] : contract['sellerAddress']} >
                                                    {current_user_type == 'seller' ? (contract['buyerprofileName'] != '' && contract['buyerprofileName'] != null ? contract['buyerprofileName'] : contract['buyerAddress']) : (contract['sellerprofileName'] != '' && contract['sellerprofileName'] != null ? contract['sellerprofileName'] : contract['sellerAddress'])}</a>
                                            </div>

                                        </div>



                                    </div>


                                    <div className="d-flex flex-wrap flex-stack">

                                        <div className="d-flex flex-column flex-grow-1 pe-8">

                                            <div className="d-flex flex-wrap">

                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                    <div className="d-flex align-items-center">

                                                        {/* <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                        </svg>
                                                    </span> */}

                                                        <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['totalAmount'] != null ? contract['totalAmount'].toFixed(2) : ''}◎</div>
                                                    </div>


                                                    <div className="fw-bold fs-6">Total Cost</div>

                                                </div>


                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                    <div className="d-flex align-items-center">

                                                        {/* <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                                                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                                                        </svg>
                                                    </span> */}

                                                        <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['escrowHoldingCost'] != null ? contract['escrowHoldingCost'].toFixed(2) : ''}◎</div>
                                                    </div>


                                                    <div className="fw-bold fs-6">Escrow Holding</div>

                                                </div>


                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                    <div className="d-flex align-items-center">

                                                        {/* <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                        </svg>
                                                    </span> */}

                                                        <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['totalPaid'] != null ? contract['totalPaid'].toFixed(2) : ''}◎</div>
                                                    </div>


                                                    <div className="fw-bold fs-6">Total Paid</div>

                                                </div>

                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                    <div className="d-flex align-items-center">
                                                        {/* 
                                                    <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                        </svg>
                                                    </span> */}

                                                        <div className="fs-2 fw-bolder" data-kt-countup-prefix="◎">{contract['reaminingCost'] != null ? contract['reaminingCost'].toFixed(2) : ''}◎</div>
                                                    </div>


                                                    <div className="fw-bold fs-6">Remaining</div>

                                                </div>

                                            </div>

                                        </div>


                                        {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                <span className="fw-bold fs-6">{contract['progress']}</span>
                                                <span className="fw-bolder fs-6">{contract['progress'] == 'COMPLETED' ? 100 : ''}</span>
                                            </div>
                                            <div className="h-5px mx-3 w-100 bg-light mb-3">
                                                <div className={contract['progress'] == 'COMPLETED' ? "bg-primary rounded h-5px" : "bg-success rounded h-5px"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "30%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>

                            </div>



                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">

                                <li className="nav-item mt-2">
                                    <Link className="nav-link text-active-primary ms-0 me-10 py-5 active" to={{ pathname: "/project-milestone/" + contract['projectId'] }}>Milestones</Link>
                                </li>


                                <li className="nav-item mt-2">
                                    <Link className="nav-link text-active-primary ms-0 me-10 py-5 " to={{ pathname: "/project-details/" + contract['projectId'] }}>Description and Terms</Link>
                                </li>


                                <li className="nav-item mt-2">
                                    <Link className="nav-link text-active-primary ms-0 me-10 py-5" to={{ pathname: "/project-files/" + contract['projectId'] }}>Files</Link>
                                </li>


                                <li className="nav-item mt-2">
                                    <Link className="nav-link text-active-primary ms-0 me-10 py-5" to={{ pathname: "/project-feedback/" + contract['projectId'] }}>Feedback</Link>
                                </li>




                            </ul>

                        </div>

                    </div>

                    <div className="d-flex flex-wrap flex-stack">

                        <h3 className="fw-bolder my-2">Project Milestones
                        </h3>






                    </div>
                    <div className="tab-content pt-8">
                        <div className="card card-flush">
                            <div className="card-body pt-3">

                                <table id="kt_profile_overview_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">

                                    <thead className="fs-7 text-gray-400 text-uppercase">
                                        <tr>
                                            <th className="min-w-100px">Name</th>
                                            <th className="min-w-50px">Description</th>
                                            <th className="min-w-20px">Due Date</th>
                                            <th className="min-w-10px">Cost (SOL)</th>
                                            <th className="min-w-50px">Status</th>
                                            <th className="min-w-200px"></th>
                                            <th className="min-w-90px"></th>
                                        </tr>
                                    </thead>

                                    {('mileStones' in contract && contract['mileStones'].length > 0) ? (
                                        <tbody className="fs-6">
                                            {contract['mileStones'].map((milestone, index) => (
                                                <tr key={milestone.mileStoneId}>
                                                    <td className="fw-bolder">
                                                        {milestone.mileStoneTitle}
                                                    </td>
                                                    <td>{milestone.mileStoneDescription}</td>
                                                    <td>{gettime(milestone.dueDate)}</td>
                                                    <td>{milestone.amount}</td>
                                                    <td>

                                                        <span className="badge badge-light-primary fw-bolder me-auto">
                                                            {milestone.status == 'MILESTONE CREATED' && current_user_type == 'seller' ? ('Contract created awaiting your confirmation') : ''}
                                                            {milestone.status == 'MILESTONE CREATED' && current_user_type == 'buyer' ? ('Contract created awaiting seller confirmation') : ''}
                                                            {milestone.status == 'IN PROGRESS' ? ('Milestone Accepted') : ''}
                                                            {milestone.status == 'REJECTED' ? ('Contract Rejected By Seller') : ''}
                                                            {milestone.status == 'In Review' ? ('In Review') : ''}
                                                            {milestone.status == 'CHANGE REQUESTED' ? ('In Revision') : ''}
                                                            {milestone.status == 'TRANSACTION ISSUE' ? ('Some Issues On Transaction') : ''}
                                                            {milestone.status == 'COMPLETED' ? ('Work Completed') : ''}
                                                            {milestone.status == 'CONTRACTWITHDRAWN' ? ('Contract Withdrawn') : ''}
                                                            {milestone.status == 'IN DISPUTE' ? ('In Dispute') : ''}
                                                            {milestone.status == 'DRAFT' ? ('In Draft') : ''}


                                                        </span>
                                                    </td>
                                                    <td className="">
                                                       
                                                        {milestone.status == 'CHANGE REQUESTED' && current_user_type == 'seller' ? (
                                                            <div>
                                                                <div>
                                                                    {('workRevisionDocuments' in milestone && milestone['workRevisionDocuments'].length > 0) ?
                                                                        <div>
                                                                            <h4>Buyer Reponse</h4>
                                                                            <p className="text-muted">{milestone['workRevisionDocuments'][milestone['workRevisionDocuments'].length - 1].description}</p>
                                                                        </div>

                                                                        : ''}
                                                                </div>
                                                                {/* <div className="d-flex flex-column mb-8 fv-row">

                                                                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                        <span className="">Comments</span>

                                                                    </label>

                                                                    <textarea
                                                                        className="form-control form-control-solid"
                                                                        rows={3} name="description"
                                                                        placeholder="Details"
                                                                        onChange={(e) => setsubmittext(e.target.value)}></textarea>


                                                                </div> */}
                                                            </div>
                                                        ) : ''}

                                                        {
                                                            milestone.status == 'MILESTONE CREATED' && current_user_type == 'seller' ? (
                                                                <div className="flex-container">
                                                                    <div>
                                                                        <button onClick={() => approve_contract(milestone.mileStoneId)} className="mx-5 btn btn-accept btn-sm">Accept Milestone</button>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => reject_contract(milestone.mileStoneId)} className="btn btn-reject btn-sm">Reject Milestone</button>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        }
                                                        {
                                                            milestone.status == 'IN PROGRESS' && current_user_type == 'seller' ?
                                                                (<div>
                                                                    <div className="flex-container">
                                                                        <div className="flex-child magenta">
                                                                            <button onClick={() => submit_work_modal(milestone.mileStoneId, "Submit Work")} className="btn btn-submit-work btn-sm">Submit Work</button>
                                                                        </div>

                                                                    </div>
                                                                </div>)
                                                                : ''
                                                        }
                                                        {
                                                            milestone.status == 'CHANGE REQUESTED' && current_user_type == 'seller' ?
                                                                (<div>

                                                                    <div className="flex-container">

                                                                        <div className="flex-child magenta">
                                                                            <button onClick={() => submit_work_modal(milestone.mileStoneId, "Change Revision")} className="btn btn-submit-work btn-sm">Submit Revision</button>
                                                                        </div>

                                                                        
                                                                    </div>
                                                                </div>)
                                                                : ''
                                                        }
                                                        <Modal show={submitWorkModal} onHide={handle_close_submit_work_modal} backdrop="static">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>{submitWorkModalTitle}</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <form onSubmit={submit_work} id="modal_request_change_form" className="form" action="#">
                                                                    {milestone.status == 'IN PROGRESS' && current_user_type == 'seller' ? (

                                                                        <div className="">
                                                                            <div className="d-flex flex-column mb-8 fv-row">

                                                                                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                                    <span className="">Enter Details About Work</span>

                                                                                </label>

                                                                                <textarea
                                                                                    className="form-control form-control-solid"
                                                                                    rows={3} name="description"
                                                                                    placeholder="Details"
                                                                                    onChange={(e) => setsubmittext(e.target.value)}></textarea>


                                                                            </div>
                                                                            <div style={{ padding: "10px" }} className="flex-child green mb-5 attach-file">
                                                                                <div className="image-upload d-flex flex-column fv-row">
                                                                                    <label htmlFor="file-input">
                                                                                        
                                                                                        {/* <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                                <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                                                <rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1" />
                                                                                                <path d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z" fill="#000000" fillRule="nonzero" />
                                                                                            </g>
                                                                                        </svg></span><span style={{ paddingLeft: "10px" }}>{file}</span> */}
                                                                                        <span className="fs-6 fw-bold">Attach File</span>
                                                                                    </label>
                                                                                    <input id="file-input" type='file' ref={fileInput} name="selectedFile"
                                                                                        onChange={(event) => handleChange(event)} onClick={() => setUploadSucess(false)} accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*" />
                                                                                </div>
                                                                            </div>
                                                                            {uploadSucess && <div><span className="badge badge-light-success ms-2 fs-7">Upload is successful!</span></div>}
                                                                        </div>
                                                                    ) : ''}
                                                                    {milestone.status == 'CHANGE REQUESTED' && current_user_type == 'seller' ? (
                                                                        <div>
                                                                            <div>
                                                                                {('workRevisionDocuments' in milestone && milestone['workRevisionDocuments'].length > 0) ?
                                                                                    <div>
                                                                                        <h4>Buyer Reponse</h4>
                                                                                        <p className="text-muted">{milestone['workRevisionDocuments'][milestone['workRevisionDocuments'].length - 1].description}</p>
                                                                                    </div>

                                                                                    : ''}
                                                                            </div>
                                                                            <div className="d-flex flex-column mb-8 fv-row">

                                                                                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                                    <span className="">Comments</span>

                                                                                </label>

                                                                                <textarea
                                                                                    className="form-control form-control-solid"
                                                                                    rows={3} name="description"
                                                                                    placeholder="Details"
                                                                                    onChange={(e) => setsubmittext(e.target.value)}></textarea>


                                                                            </div>
                                                                            <div style={{ padding: "10px" }} className="flex-child green mb-5 attach-file">
                                                                            <div className="image-upload d-flex flex-column fv-row">
                                                                                <label htmlFor="file-input">
                                                                                    {/* <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                                            <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                                            <rect fill="#000000" opacity="0.3" x="11" y="2" width="2" height="14" rx="1" />
                                                                                            <path d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z" fill="#000000" fillRule="nonzero" />
                                                                                        </g>
                                                                                    </svg></span><span style={{ paddingLeft: "10px" }}>{file}</span> */}
                                                                                    <span className="fs-6 fw-bold">Attach File</span>
                                                                                </label>
                                                                                <input id="file-input" type='file' ref={fileInput} name="selectedFile"
                                                                                    onChange={(event) => handleChange(event)} onClick={() => setUploadSucess(false)} accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*" />
                                                                            </div>
                                                                        </div>

                                                                        {uploadSucess && <div><span className="badge badge-light-success ms-2 fs-7">Upload is successful!</span></div>}
                                                                        </div>
                                                                    ) : ''}

                                                                    <div className="text-center">
                                                                        <button type="reset" className="btn btn-light me-3" onClick={handle_close_submit_work_modal}>Cancel</button>
                                                                        <button onClick={submit_work} type="submit" className="btn btn-primary">
                                                                            <span className="indicator-label">Submit</span>
                                                                        </button>
                                                                    </div>

                                                                </form>
                                                            </Modal.Body>
                                                        </Modal>
                                                        {
                                                            milestone.status == 'In Review' && current_user_type == 'buyer' ? (
                                                                <div>
                                                                    <div>
                                                                        {('workRevisionDocuments' in milestone && milestone['workRevisionDocuments'].length > 0) ?
                                                                            <div>
                                                                                <h4>Seller Reponse</h4>
                                                                                <p className="text-muted">{milestone['workRevisionDocuments'][milestone['workRevisionDocuments'].length - 1].description}</p>
                                                                            </div>

                                                                            : ''}
                                                                    </div>
                                                                    <div className="flex-container">
                                                                        <div>
                                                                            <button onClick={() => release_payment(milestone.mileStoneId)} className="mx-5 btn btn-release btn-sm">Release Payment</button>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={() => request_change_modal(milestone.mileStoneId)} className="btn btn-request btn-sm">Request Change</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ) : ''

                                                        }
                                                        <Modal show={requestChange} onHide={handle_close_request_change_modal} backdrop="static">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Request Change</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <form onSubmit={submit_request_change} id="modal_request_change_form" className="form" action="#">
                                                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                            <span className="required">Provide details about change request</span>
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control form-control-solid"
                                                                            rows={3} name="request_change"
                                                                            placeholder="Details"
                                                                            onChange={(e) => setrequestChangeDetails(e.target.value)}></textarea>

                                                                    </div>


                                                                    <div className="text-center">
                                                                        <button type="reset" className="btn btn-light me-3" onClick={handle_close_request_change_modal}>Cancel</button>
                                                                        <button onClick={submit_request_change} type="submit" className="btn btn-primary">
                                                                            <span className="indicator-label">Submit</span>
                                                                        </button>
                                                                    </div>

                                                                </form>
                                                            </Modal.Body>
                                                        </Modal>
                                                        {
                                                            (milestone.status == 'MILESTONE CREATED' || milestone.status == 'REJECTED')
                                                                && current_user_type == 'buyer' ?
                                                                (<button onClick={() => withdraw_amount(milestone.mileStoneId)} className="btn btn-withdraw btn-sm mx-2">Withdraw Contract</button>)
                                                                : ''
                                                        }
                                                        {/* If all milestone in draft and contract progress is draft */}
                                                        {/* {
                                                            ((index == 0) && (contract['progress'] == 'DRAFT') && milestone.status == 'DRAFT' && current_user_type == 'buyer')
                                                                ? (<button onClick={() => initiate_contract(milestone.mileStoneId, milestone.amount)} className="btn btn-payment btn-sm mx-2">Activate With Payment</button>)
                                                                : ''
                                                        } */}

                                                        {
                                                            ((index == 0) && milestone.status == 'DRAFT' && current_user_type == 'buyer')
                                                                ? (<button onClick={() => initiate_contract(milestone.mileStoneId, milestone.amount)} className="btn btn-payment btn-sm mx-2">Continue With Payment</button>)
                                                                : ''
                                                        }


                                                        {
                                                            milestone.status == 'TRANSACTION ISSUE' ?
                                                                (<button onClick={() => retry_api_call(milestone.mileStoneId)} className="btn btn-primary btn-sm">Retry</button>)
                                                                : ''
                                                        }

                                                        {

                                                            ((index == 0) && milestone.status == 'CONTRACTWITHDRAWN')
                                                                && current_user_type == 'buyer' ? (
                                                                <button onClick={() => activate_contract(milestone.mileStoneId, milestone.amount)} className="btn btn-activate btn-sm">Activate Milestone</button>)
                                                                : ''
                                                        }

                                                        {

                                                            ((index > 0) && contract['mileStones'][index - 1]['status'] == 'COMPLETED' && milestone.status == 'CONTRACTWITHDRAWN')
                                                                && current_user_type == 'buyer' ? (
                                                                <button onClick={() => activate_contract(milestone.mileStoneId, milestone.amount)} className="btn btn-activate btn-sm">Activate Milestone</button>)
                                                                : ''
                                                        }

                                                        {

                                                            ((index > 0) && contract['mileStones'][index - 1]['status'] == 'COMPLETED' && milestone.status == 'DRAFT')
                                                                && current_user_type == 'buyer' ? (
                                                                <button onClick={() => activate_contract(milestone.mileStoneId, milestone.amount)} className="btn btn-activate btn-sm">Activate Milestone</button>)
                                                                : ''
                                                        }


                                                    </td>
                                                    <td>
                                                        {

                                                            (milestone.allTransactionSignatures != null && milestone.allTransactionSignatures.length > 0) ? (<div><a href="#" onClick={() => view_transactions(milestone.allTransactionSignatures)} className="view-transaction-btn">View Transactions </a></div>) : ''
                                                        }

                                                        {

                                                            (milestone.workRevisionDocuments != null && milestone.workRevisionDocuments.length > 0) ? (<div><a href="#" onClick={() => view_revision_history(milestone.workRevisionDocuments)} className="view-transaction-btn">View Submission History </a></div>) : ''
                                                        }

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : ''}

                                </table>

                            </div>
                        </div>
                    </div>






                </div>
            }
        </div>

        <Modal show={viewTransaction} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Milestone Transactions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table id="kt_profile_overview_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">

                    <thead className="fs-7 text-gray-400 text-uppercase">
                        <tr>

                            <th className="min-w-20">#</th>
                            <th className="min-w-20px">Transaction Type</th>
                            <th className="min-w-200px">Transactions Url</th>
                        </tr>
                    </thead>
                    {(currentTransaction.length > 0) ? (
                        <tbody className="fs-6">
                            {currentTransaction.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {transaction.signatureType == 'INITIATED' && 'Milestone Initiated'}
                                        {transaction.signatureType == 'Accepted' && 'Milestone Accepted'}
                                        {transaction.signatureType == 'Completed' && 'Payment Released'}
                                        {transaction.signatureType == 'ContractWithDrawn' && 'Milestone Withdrawn'}

                                    </td>


                                    <td className="transaction-hash">
                                        {process.env.REACT_APP_SOLANA_NETWORK == 'mainnet-beta' ?
                                            <div>
                                                <a href={"https://solscan.io/tx/" + transaction.signatureHash + '?cluster=devnet'} target={"blank"}>View On Solscan</a>
                                            </div>
                                            :
                                            <div>
                                                <a href={"https://solscan.io/tx/" + transaction.signatureHash} target={"blank"}>View On Solscan</a>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : ''}


                </table>
            </Modal.Body>


        </Modal>

        <Modal show={viewRevisionHistory} onHide={() => setviewRevisionHistory(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Revision History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table id="kt_profile_overview_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">

                    <thead className="fs-7 text-gray-400 text-uppercase">
                        <tr>

                            <th className="min-w-20">#</th>
                            <th className="min-w-20px">Description</th>
                            <th className="min-w-200px">Status</th>
                        </tr>
                    </thead>
                    {(currentRevisionHistory.length > 0) ? (
                        <tbody className="fs-6">
                            {currentRevisionHistory.map((revision_history, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {revision_history.description}
                                    </td>


                                    <td>
                                        {revision_history.status}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : ''}


                </table>
            </Modal.Body>


        </Modal>
    </>);
};