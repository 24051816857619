export class chatMessagesModel {
  Delivered: string;
  FromUserID: string;
  FromUser: string;
  Message: string;
  ReadStatus: string;
  ReadTime: string;
  SentTime: string;
  ToUserID: string;
  ToUser: string;
  constructor(
    FromUserID: string = "",
    ToUserID: string = "",
    Message: string = "",
    SentTime: string = "",
    Delivered: string = "",
    ReadStatus: string = "",
    ReadTime: string = "",
    FromUser: string = "",
    ToUser: string = "",
  ) {
    this.Delivered = Delivered;
    this.FromUserID = FromUserID;
    this.FromUser = FromUser;
    this.Message = Message;
    this.ReadStatus = ReadStatus;
    this.ReadTime = ReadTime;
    this.SentTime = SentTime;
    this.ToUserID = ToUserID;
    this.ToUser = ToUser
  }
}
